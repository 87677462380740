.footer-section {
	background: #2E353D;
}

.streamline-box {
	padding: 20px;
	display: flex;
	justify-content: start;
	flex-direction: column;
	align-items: center;
}

.cxotulz-logo {
	width: 340px;
	margin-left: -1vw;
}

.menu-item {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #FFFFFF;
	cursor: pointer;
}

.footer-menu {
	position: relative;
	top: 34px;
}

.contact-section {
	display: flex;
	align-items: start;
	justify-content: bottom;
}

.contact-us-btn {
	background: #FFFFFF;
	border-radius: 50px;
	width: 50%;
	height: 55px;
	border-style: none;
	color: #2E353D;
	float: left;
	margin-left: 50px;
}

.powered-by-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.streamline-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 42px;
	line-height: 60px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
	text-align: left;
	margin-top: -80px;
	margin-left: -57px;
}

.menu-heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #FFFFFF;
}

.linkedin-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.facebook-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.linked-logo {
	margin-right: 10px;
}

.facebook-logo {
	margin-right: 7px;
	width: 29px;
	margin-left: 17px;
	margin-top: -4px;
}

.linked-in {
	margin-top: 2px;
}

.contact-input-box {
	border: 1px solid #FFFFFF;
	border-radius: 40px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	padding: 16px;
	width: 105%;
	background-color: #2E353D;
	float: left;
}

.contact-input-box::placeholder {
	color: #FFFFFF;
}

.menu-section {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	padding-top: 50px;
}

.social-handle-section {
	margin-top: 45px;
	margin-left: -179px;
}

.powered-by-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	color: #FFFFFF;
	margin-bottom: 0px !important;
}

.horizontal-line {
	border-bottom: 1px solid #ffffff;
	width: 100%;
	position: absolute;
	left: -1px;
}

.copyright-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.02em;
	color: #FFFDF7;
	padding: 20px;
}

.streamline-inner-box {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 0px;
}

.email-error-block {
	display: inline-block;
	position: relative;
	left: -50px;
}

.contact-us-btn:hover {
	background-color: #f5f4f4 !important;
}

.handle-mobile-view {
	display: none;
}

.schedule-demo-mobile-view {
	margin-left: 0px;
}

.vertical-line {
	display: flex;
	justify-content: start;
	align-items: center;
	height: 34vh;
	top: 20px;
	position: relative;
}

.streamline-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.follow-us-text {
	margin-left: -10px;
}

.streamline-text-mobile {
	font-size: 29px;
	color: #FFFFFF;
	text-align: start;
	padding-right: 221px;
	padding-left: 39px;
}