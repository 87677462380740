.hero-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #161616;
}

.cloud-mgt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #161616;
}

.under-unified-text {
    position: relative;
    top: -138px;
}

.cloud-management {
    padding: 17px;
    color: #000000;
    font-weight: 600;
    top: -15px;
    left: -15px;
    position: relative;
    animation-name: textEffect;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes textEffect {
    to {
        color: #007EA7;
    }
}

.cloud-mgt-circle {
    background-image: url("../images/cloud-mgt.svg");
    background-size: contain;
    animation: fade-in 2s ease-out forwards;
    height: 108px;
    width: 100%;
    top: -132px;
    position: relative;
    left: -36px;
    object-fit: contain;
    background-repeat: no-repeat;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
        color: #007EA7;
    }
}

.inner-hero-section {
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-left: 10vw !important;
    z-index: -1;
    position: relative;
    z-index: 99;
}

.hero-container {
    height: 100vh;
    background-image: url('/src/assets/images/top-banner-background.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.hero-container-top {
    top: 15vh;
    position: relative;
}

.effortlessly-content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 0px !important;
    margin-top: 30px;
}
.effortlessly-content-1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 24px !important;
    margin-top: 30px;
}

.service-tree {
    position: relative;
    right: 8px;
}

.graph-charts {
    position: relative;
    right: 55px;
    top: -290px;
}

.banner-clouds {
    position: relative;
    right: 63px;
    top: -988px;
}

.effortlessly-content-animation {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 0em !important;
    margin-top: -125px;
}


.hero-schedule-btn {
    color: #FFFDF7;
    background-color: #007EA7;
    border-radius: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-style: none;
    padding: 18px 30px;
    box-shadow: 1px 1px 5px #888888;
    cursor: pointer !important;
}

.hero-schedule-btn:hover {
    background-color: #006C8F !important;
    cursor: pointer !important;
}