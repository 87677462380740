@media (max-width: 1350px) {
    .main-nav {
        height: 7rem;
        grid-template-columns: 5rem 3fr 5fr 3fr 5rem;
    }
}

@media (max-width:767px) {
    .schedule-demo-btn {
        display: none;
    }
}

@media (max-width: 1199px) {
    .hamburger-menu {
        display: block !important;
        grid-column: 1/2 !important;
        margin-top: -65px !important;
        margin-left: 71px !important;
    }

    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
        background-size: cover;
        position: absolute;
        background-color: #ffffff;
        height: 15rem;
        display: grid;
        grid-column: 1/6;
        width: 100%;
        top: 70%;
        align-items: center;
        padding-left: 3rem;
        transform-origin: top;
    }

    .mobile-menu-link ul {
        height: 15rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: start;
    }

    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }

    .menu-link {
        display: none;
    }

    .logoSection {
        grid-column: 3/4 !important;
        justify-content: center !important;
        align-items: center !important;
        position: sticky !important;
        margin-top: 10px !important;
    }
}

@media (max-width: 991px) {
    /* .menu-link {
        display: none;
    } */

    .schedule-button {
        display: block;
    }

    .schedule-btn {
        padding: 12px 22px;
    }

    .nav-text {
        font-size: 16px;
    }
}

@media (max-width: 414px) {
    .cxo-logo {
        width: 10rem;
    }

    .hamburger-menu-icon {
        width: 22px;
    }

    .schedule-btn {
        padding: 10px 18px;
    }
}

@media only screen and (min-width: 2801px) and (max-width: 3500px) {
    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 32px !important;
        line-height: 42px !important;
    }

    .schedule-demo {
        top: 46px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 2501px) and (max-width: 2800px) {
    .about-container {
        overflow-y: hidden;
    }

    .email-error-message {
        padding-left: 10% !important;
        font-size: 24px !important;
    }

    .feature-subheading {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    .feature-description {
        font-size: 28px !important;
        line-height: 34px !important;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 950px !important;
    }

    .schedule-demo {
        top: 50px !important;
    }

    .feature-overall-container {
        height: 990px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 147px !important;
        width: 88% !important;
        top: -147px !important;
        left: -129px !important;
        transform: scale(0.8) !important;
    }

    .hero-container {
        height: 900px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -283px !important;
    }

    .graph-charts {
        top: 221px !important;
        left: -249px !important;
    }

    .banner-clouds {
        top: -605px !important;
        left: 0px !important;
    }

    .cost-analysis {
        top: 13vw !important;
        left: 30vw !important;
        width: 577px !important;
    }

    .actions {
        top: 10vw !important;
        left: 13vw !important;
        width: 430px !important;
    }

    .monthly-reports {
        top: 6vw !important;
        left: 20vw !important;
        width: 403px !important;
    }

    .comments {
        left: 31vw !important;
        top: 1vw;
        width: 352px !important;
    }

    .statistics {
        top: 1vw !important;
        left: 9vw !important;
        width: 489px !important;
    }

    .about-left-section {
        height: 730px !important;
    }

    .charts-section {
        position: relative;
    }

    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 32px !important;
        line-height: 37px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 700px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 77px !important;
        padding-top: 70px !important;
    }

    .contact-input {
        font-size: 20px !important;
        padding: 16px !important;
        width: 85% !important;
        margin-right: 20px !important;
    }

    .contact-btn {
        font-size: 21px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .streamline-text {
        font-size: 58px !important;
        line-height: 66px !important;
        margin-left: -154px !important;
        margin-top: -58px !important;
    }

    .vertical-line {
        height: 282px !important;
    }

    .form-container {
        height: 712px !important;
        width: 35vw !important;
        margin-left: 13% !important;
        margin-top: 3% !important;
    }

    .form-box {
        top: 86px !important;
    }

    .unlock-text {
        width: 60% !important;
        top: 22vw !important;
        font-size: 58px !important;
        line-height: 63px !important;
        right: -34px !important;
    }

    .cxo {
        right: -449px !important;
    }

    .input-box {
        font-size: 21px !important;
    }

    .error-message {
        font-size: 21px !important;
    }

    .cxo-charts {
        top: 11vw !important;
        left: 54% !important;
    }

    .dashboard {
        top: -6vw !important;
        left: 675px !important;
    }

    .hero-container-top {
        top: 82px !important;
    }

    .inner-hero-section {
        margin-top: 100px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }

    .effortlessly-content {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .effortlessly-content-1 {
        font-size: 32px !important;
        line-height: 38px !important;
    }
}

@media screen and (min-width: 2670px) and (max-width: 2800px) {
    .actions {
        top: 9vw !important;
        left: 14vw !important;
        width: 430px !important;
    }

    .cost-analysis {
        top: 12vw !important;
        left: 32vw !important;
        width: 593px !important;
    }

    .monthly-reports {
        top: 6vw !important;
        left: 21vw !important;
        width: 421px !important;
    }

    .statistics {
        top: 1vw !important;
        left: 10vw !important;
        width: 489px !important;
    }

    .comments {
        left: 30vw !important;
        top: 1vw;
        width: 346px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media screen and (min-width: 2664px) and (max-width: 2800px) {
    .streamline-text {
        margin-top: -109px !important;
    }

    .menu-section {
        padding-left: 4% !important;
    }
}

@media screen and (min-width: 2801px) and (max-width: 2945px) {
    .hero-container {
        height: 930px !important;
    }

    .email-error-message {
        padding-left: 12% !important;
        font-size: 25px !important;
    }

    .feature-subheading {
        font-size: 32px !important;
        line-height: 35px !important;
    }

    .feature-description {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    .about-left-section {
        height: 50vh !important;
    }

    .service-tree {
        top: -146px !important;
        right: -262px !important;
    }

    .graph-charts {
        top: 3px !important;
        right: 256px !important;
    }

    .banner-clouds {
        top: -839px !important;
        right: 16px !important;
    }

    .inner-hero-section {
        margin-top: -73px !important;
    }

    .hero-content {
        line-height: 148% !important;
    }

    .cost-analysis {
        top: 13vw !important;
        left: 30vw !important;
        width: 437px !important;
    }

    .monthly-reports {
        top: 6vw !important;
        left: 21vw !important;
        width: 389px !important;
    }

    .actions {
        top: 9vw !important;
        left: 16vw !important;
        width: 351px !important;
    }

    .comments {
        left: 30vw !important;
        top: 1vw;
        width: 346px !important;
    }

    .statistics {
        top: 1vw !important;
        left: 12vw !important;
        width: 456px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }

    .effortlessly-content {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .effortlessly-content-1 {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .streamline-text {
        font-size: 58px !important;
        line-height: 66px !important;
        margin-left: -154px !important;
        margin-top: -95px !important;
    }

    .vertical-line {
        height: 282px !important;
    }
}

@media screen and (min-width:2801px) and (max-width:3500px) {
    .demo-container {
        margin-top: 151px !important;
        height: 970px !important;
    }

    .feature-overall-container {
        height: 990px !important;
    }

    .form-container {
        height: 712px !important;
        width: 35vw !important;
        margin-left: 13% !important;
        margin-top: 3% !important;
    }

    .form-box {
        top: 85px !important;
    }

    .unlock-text {
        width: 60% !important;
        top: 20vw !important;
        font-size: 58px !important;
        line-height: 63px !important;
        right: -34px !important;
    }

    .cxo {
        right: -449px !important;
    }

    .input-box {
        font-size: 21px !important;
    }

    .error-message {
        font-size: 21px !important;
    }

    .cxo-charts {
        top: 11vw !important;
        left: 54% !important;
    }

    .dashboard {
        top: -6vw !important;
        left: 675px !important;
    }

    .hero-container-top {
        top: 82px !important;
    }

    .inner-hero-section {
        margin-top: 100px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .effortlessly-content {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .effortlessly-content-1 {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .banner-clouds {
        top: -554px !important;
        right: 16px !important;
    }

    .service-tree {
        top: 125px !important;
        right: -262px !important;
    }

    .graph-charts {
        top: 274px !important;
        right: 256px !important;
    }
}

@media screen and (min-width: 2946px) and (max-width: 3500px) {
    .hero-container {
        height: 930px !important;
    }

    .email-error-message {
        padding-left: 12% !important;
        font-size: 25px !important;
    }

    .feature-subheading {
        font-size: 32px !important;
        line-height: 35px !important;
    }

    .feature-description {
        font-size: 30px !important;
        line-height: 33px !important;
    }

    .banner-clouds {
        top: -136px !important;
        right: 498px !important;
    }

    .graph-charts {
        top: 277px !important;
        right: 48px !important;
    }

    .service-tree {
        top: 127px !important;
        right: -484px !important;
    }

    .inner-hero-section {
        margin-top: 100px !important;
    }

    .hero-content {
        line-height: 148% !important;
    }

    .effortlessly-content {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .effortlessly-content-1 {
        font-size: 32px !important;
        line-height: 38px !important;
    }

    .cost-analysis {
        top: 11vw !important;
        left: 28vw !important;
        width: 437px !important;
    }

    .monthly-reports {
        top: 5vw !important;
        left: 21vw !important;
        width: 389px !important;
    }

    .actions {
        top: 9vw !important;
        left: 17vw !important;
        width: 351px !important;
    }

    .comments {
        left: 30vw !important;
        top: 1vw;
        width: 346px !important;
    }

    .statistics {
        top: 1vw !important;
        left: 12vw !important;
        width: 456px !important;
    }

    .about-left-section {
        height: 47vh !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }

    .streamline-text {
        font-size: 58px !important;
        line-height: 70px !important;
        margin-left: -194px !important;
        margin-top: -95px !important;
    }

    .vertical-line {
        height: 282px !important;
    }
}

@media screen and (min-width:3000px) {
    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
        padding-bottom: 3% !important;
    }

    .cxoTulz-content {
        font-size: 35px !important;
        line-height: 45px !important;
    }

    .about-left-section {
        height: 46vh !important;
    }

    .demo-container {
        height: 1000px !important;
    }
}

@media screen and (min-width:3075px) and (max-width:3099px) {
    .dashboard {
        top: -5vw !important;
        left: 673px !important;
    }

    .cxo-charts {
        top: 10vw !important;
        left: 54% !important;
    }

    .cxo-dashboard {
        position: relative;
        float: right;
    }

    .chart-section-inner {
        height: 850px !important;
        position: relative !important;
    }

    .unlock-text {
        width: 60% !important;
        top: -8vw !important;
        font-size: 58px !important;
        line-height: 63px !important;
        right: -34px !important;
    }
}

@media screen and (min-width:3100px) and (max-width:3500px) {
    .dashboard {
        top: 6vw !important;
        left: 673px !important;
    }

    .cxo-charts {
        top: 10vw !important;
        left: 67% !important;
    }

    .cxo-dashboard {
        position: relative;
        float: right;
    }

    .chart-section-inner {
        height: 850px !important;
        position: relative !important;
    }

    .unlock-text {
        width: 60% !important;
        top: -8vw !important;
        font-size: 58px !important;
        line-height: 63px !important;
        right: -34px !important;
    }

    .menu-section {
        padding-left: 3% !important;
    }
}

@media only screen and (min-width: 2232px) and (max-width: 2500px) {
    .about-container {
        overflow-y: hidden;
    }

    .email-error-message {
        padding-left: 10% !important;
        font-size: 23px !important;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 980px !important;
    }

    .effortlessly-content {
        font-size: 32px !important;
        line-height: 39px !important;
    }

    .effortlessly-content-1 {
        font-size: 32px !important;
        line-height: 39px !important;
    }

    .feature-overall-container {
        height: 1095px !important;
    }

    .schedule-demo {
        top: 59px !important;
        font-size: 58px !important;
        line-height: 60px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 147px !important;
        width: 95% !important;
        top: -147px !important;
        left: -133px !important;
        transform: scale(0.8) !important;
    }

    .hero-container {
        height: 945px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -283px !important;
    }

    .graph-charts {
        top: 221px !important;
        left: -249px !important;
    }

    .banner-clouds {
        top: -605px !important;
        left: 0px !important;
    }

    .hero-content {
        font-size: 58px !important;
        font-weight: 500 !important;
    }

    .cost-analysis {
        top: 16vw !important;
        left: 30vw !important;
        width: 529px !important;
    }

    .actions {
        top: 11vw !important;
        left: 9vw !important;
        width: 430px !important;
    }

    .monthly-reports {
        top: 7vw !important;
        left: 17vw !important;
        width: 407px !important;
    }

    .comments {
        left: 28vw !important;
        top: 1vw;
        width: 352px !important;
    }

    .statistics {
        top: 0vw !important;
        left: 5vw !important;
        width: 489px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 32px !important;
        line-height: 39px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 700px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 77px !important;
        padding-top: 70px !important;
    }

    .contact-input {
        font-size: 21px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 85% !important;
        margin-right: 20px !important;
    }

    .contact-btn {
        font-size: 20px !important;
        line-height: 25px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .streamline-text {
        font-size: 53px !important;
        line-height: 60px !important;
        margin-left: -113px !important;
        margin-top: -50px !important;
    }

    .vertical-line {
        height: 285px !important;
    }

    .menu-section {
        padding-left: 4% !important;
    }

    .form-container {
        height: 740px !important;
        width: 35vw !important;
        margin-left: 11% !important;
        margin-top: 3% !important;
    }

    .form-box {
        top: 86px !important;
    }

    .error-message {
        font-size: 21px !important;
    }

    .name-box {
        width: 13vw !important;
        font-size: 21px !important;
    }

    .unlock-text {
        width: 64% !important;
        top: 26vw !important;
        font-size: 46px !important;
        line-height: 51px !important;
        right: 3% !important;
    }

    .cxo-charts {
        top: 14vw !important;
        left: 54% !important;
    }

    .dashboard {
        top: -5vw !important;
        left: 668px !important;
    }

    .hero-container-top {
        top: 136px !important;
    }

    .inner-hero-section {
        margin-top: 45px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }

    .cxo {
        top: -106px !important;
        right: -366px !important;
    }
}

@media only screen and (min-width: 2301px) and (max-width: 2410px) {
    .cost-analysis {
        top: 14vw !important;
        left: 29vw !important;
        width: 556px !important;
    }

    .actions {
        top: 10vw !important;
        left: 9vw !important;
        width: 430px !important;
    }

    .monthly-reports {
        top: 6vw !important;
        left: 17vw !important;
        width: 407px !important;
    }

    .comments {
        left: 28vw !important;
        top: 1vw;
        width: 352px !important;
    }

    .statistics {
        top: -1vw !important;
        left: 5vw !important;
        width: 489px !important;
    }

    .vertical-line {
        height: 280px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 2411px) and (max-width: 2500px) {
    .statistics {
        top: 1vw !important;
        left: 5vw !important;
        width: 489px !important;
    }

    .comments {
        left: 28vw !important;
        top: 1vw;
        width: 352px !important;
    }

    .monthly-reports {
        top: 6vw !important;
        left: 17vw !important;
        width: 444px !important;
    }

    .cost-analysis {
        top: 14vw !important;
        left: 28vw !important;
        width: 614px !important;
    }

    .actions {
        top: 10vw !important;
        left: 9vw !important;
        width: 430px !important;
    }

    .about-left-section {
        height: 730px !important;
    }

    .vertical-line {
        height: 280px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

/* hero section media query start */
@media only screen and (min-width: 2101px) and (max-width: 2231px) {
    .about-container {
        overflow-y: hidden;
    }

    .email-error-message {
        padding-left: 10% !important;
        font-size: 19px !important;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 980px !important;
    }

    .feature-overall-container {
        height: 1030px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 147px !important;
        width: 79% !important;
        top: -132px !important;
        left: -36px !important;
        transform: scale(1) !important;
    }

    .hero-container {
        height: 925px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -283px !important;
    }

    .graph-charts {
        top: 221px !important;
        left: -249px !important;
    }

    .banner-clouds {
        top: -605px !important;
        left: 0px !important;
    }

    .hero-content {
        font-size: 52px !important;
        font-weight: 500 !important;
    }

    .cost-analysis {
        top: 16vw !important;
        left: 29vw !important;
        width: 526px !important;
    }

    .actions {
        top: 11vw !important;
        left: 9vw !important;
        width: 430px !important;
    }

    .monthly-reports {
        top: 7vw !important;
        left: 17vw !important;
        width: 407px !important;
    }

    .comments {
        left: 28vw !important;
        top: 1vw;
        width: 352px !important;
    }

    .statistics {
        top: 0vw !important;
        left: 5vw !important;
        width: 489px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 30px !important;
        line-height: 37px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 735px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 77px !important;
        padding-top: 70px !important;
    }

    .contact-input {
        font-size: 19px !important;
        padding: 17px !important;
        width: 85% !important;
        margin-right: 20px !important;
    }

    .contact-btn {
        font-size: 20px !important;
        line-height: 24px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .streamline-text {
        font-size: 48px !important;
        line-height: 60px !important;
        margin-left: -113px !important;
        margin-top: -50px !important;
    }

    .vertical-line {
        height: 282px !important;
    }

    .form-container {
        height: 700px !important;
        width: 37vw !important;
        margin-top: 4%;
    }

    .form-box {
        top: 73px !important;
    }

    .schedule-demo {
        top: 50px !important;
    }

    .unlock-text {
        font-size: 46px !important;
        line-height: 50px !important;
        width: 68% !important;
        top: 28vw !important;
        right: 7% !important;
    }

    .cxo {
        right: -380px !important;
    }

    .cxo-charts {
        top: -8vw !important;
        left: 43% !important;
    }

    .dashboard {
        top: -13vw !important;
    }

    .hero-container-top {
        top: 137px !important;
    }

    .inner-hero-section {
        margin-top: 100px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

/* hero section media query start */
@media only screen and (min-width: 1971px) and (max-width: 2100px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 950px !important;
    }

    .feature-overall-container {
        height: 1015px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 147px !important;
        width: 84% !important;
        top: -132px !important;
        left: -45px !important;
        transform: scale(1) !important;
    }

    .hero-container {
        height: 1000px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -243px !important;
    }

    .graph-charts {
        top: 221px !important;
        left: -278px !important;
    }

    .banner-clouds {
        top: -605px !important;
        left: 0px !important;
    }

    .hero-content {
        font-size: 62px !important;
        font-weight: 500 !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 29vw !important;
        width: 470px !important;
    }

    .actions {
        top: 14vw !important;
        left: 10vw !important;
        width: 400px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 18vw !important;
        width: 368px !important;
    }

    .comments {
        left: 29vw !important;
        top: 4vw;
        width: 329px !important;
    }

    .statistics {
        top: 4vw !important;
        left: 5vw !important;
        width: 412px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 770px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 77px !important;
        padding-top: 70px !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 85% !important;
        margin-right: 20px !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 55px !important;
        margin-left: -93px !important;
    }

    .menu-section {
        padding-left: 2% !important;
    }

    .vertical-line {
        height: 285px !important;
    }

    .form-container {
        height: 700px !important;
        width: 34vw !important;
        margin-left: 13% !important;
        margin-top: 3%;
    }

    .form-box {
        height: 467px !important;
        top: 42px !important;
    }

    .schedule-demo {
        top: 58px !important;
    }

    .unlock-text {
        width: 54% !important;
        top: 28vw !important;
        font-size: 45px !important;
        line-height: 50px !important;
    }

    .cxo-charts {
        top: -9vw !important;
        left: 42% !important;
    }

    .dashboard {
        top: -15vw !important;
    }

    .hero-container-top {
        top: 224px !important;
    }

    .inner-hero-section {
        margin-top: 23px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }

    .email-error-message {
        padding-left: 10% !important;
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 2001px) and (max-width: 2100px) {
    .vertical-line {
        height: 285px !important;
    }
}

@media only screen and (min-width: 1801px) and (max-width: 1970px) {
    .about-container {
        overflow-y: hidden !important;
    }

    .demo-container {
        height: 930px !important;
    }

    .feature-overall-container {
        height: 920px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .cloud-mgt-circle {
        height: 149px !important;
        width: 117% !important;
        top: -149px !important;
        left: -176px !important;
        transform: scale(0.7);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 50px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 960px !important;
    }

    .hero-content {
        font-size: 52px !important;
        font-weight: 500 !important;
    }

    .actions {
        top: 15vw !important;
        left: 7vw !important;
        width: 361px !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 27vw !important;
        width: 478px !important;
    }

    .comments {
        left: 26vw !important;
        top: 3vw;
        width: 329px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 360px !important;
    }

    .statistics {
        top: 4vw !important;
        left: 2vw !important;
        width: 412px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 58px !important;
        line-height: 65px !important;
    }

    .cxoTulz-content {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 705px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 77px !important;
        padding-top: 70px !important;
    }

    .form-container {
        height: 700px !important;
        width: 37vw !important;
        margin-left: 10vw !important;
        margin-top: 4%;
    }

    .schedule-demo {
        top: 45px !important;
    }

    .form-box {
        top: 75px !important;
    }

    .cxo-charts {
        top: -10vw !important;
        right: -21vw !important;
    }

    .dashboard {
        top: -15vw !important;
    }

    .unlock-text {
        width: 37% !important;
        top: 32vw !important;
        font-size: 38px !important;
        width: 53% !important;
    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 34vh !important;
    }

    .streamline-text {
        font-size: 41px !important;
        line-height: 55px !important;
        margin-left: -87px !important;
    }

    .menu-section {
        padding-left: 3% !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 1970px) {
    .actions {
        top: 15vw !important;
        left: 5vw !important;
        width: 370px !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 26vw !important;
        width: 510px !important;
    }
}

@media only screen and (min-width: 1971px) and (max-width: 1981px) {
    .banner-clouds {
        position: relative;
        right: 63px !important;
        top: -633px !important;
        left: -268px !important;
    }

    .service-tree {
        position: relative;
        right: 8px !important;
    }

    .graph-charts {
        position: relative;
        right: 55px !important;
        top: -221px !important;
        left: 198px !important;
    }

    .vertical-line {
        height: 285px !important;
    }
}

@media only screen and (min-width: 1971px) and (max-width: 1971px) {
    .banner-clouds {
        position: relative;
        right: 63px !important;
        top: -890px !important;
        left: -35px !important;
    }

    .service-tree {
        position: relative;
        right: 8px !important;
    }

    .graph-charts {
        position: relative;
        right: 55px !important;
        top: -221px !important;
        left: -42px !important;
    }

    .form-box {
        height: 459px !important;
    }

    .form-container {
        height: 74vh !important;
        margin-top: 4%;
    }

    .schedule-demo {
        top: 43px !important;
    }
}

@media only screen and (min-width: 1800px) and (max-width: 1970px) {
    .vertical-line {
        height: 290px !important;
    }
}

@media only screen and (min-width: 1700px) and (max-width: 1799px) {
    .about-container {
        overflow-y: hidden;
    }

    .schedule-demo {
        font-size: 52px !important;
        line-height: 58px !important;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 809px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .effortless-content {
        font-size: 52px !important;
        line-height: 58px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .cloud-mgt-circle {
        height: 152px !important;
        width: 131% !important;
        top: -152px !important;
        left: -181px !important;
        transform: scale(0.7);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 50px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 960px !important;
    }

    .hero-content {
        font-size: 52px !important;
        font-weight: 500 !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 52px !important;
        line-height: 60px !important;
    }

    .cxoTulz-content {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 65px !important;
        padding-top: 70px !important;
        font-size: 52px !important;
    }

    .menu-section {
        padding-left: 3% !important;
    }

    .streamline-text {
        font-size: 38px !important;
        line-height: 50px !important;
        margin-top: -90px !important;
        margin-left: -86px !important;
    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 290px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1700px) and (max-width: 1741px) {
    .demo-container {
        height: 930px !important;
    }

    .cxo-charts {
        top: -10vw !important;
        right: 0vw !important;
        left: 17%;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -37vw !important;
    }

    .cxo {
        top: -106px !important;
        right: -305px !important;
    }

    .schedule-demo {
        font-size: 58px !important;
        top: -121px !important;
    }

    .form-box {
        top: -108px !important;
    }

    .unlock-text {
        top: 34vw !important;
        right: -1vw !important;
        line-height: 41px !important;
        font-size: 35px !important;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .form-container {
        height: 680px !important;
        margin-top: 5% !important;
        width: 38vw !important;
        margin-left: 11vw !important;
    }

    .statistics {
        top: 4vw !important;
        left: 2vw !important;
        width: 412px !important;
    }

    .comments {
        left: 26vw !important;
        top: 4vw;
        width: 287px !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 27vw !important;
        width: 455px !important;
    }

    .actions {
        top: 14vw !important;
        left: 5vw !important;
        width: 374px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 333px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1699px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 840px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .effortless-content {
        font-size: 46px !important;
        line-height: 55px !important;
    }

    .cloud-mgt-circle {
        height: 143px !important;
        width: 150% !important;
        top: -143px !important;
        left: -216px !important;
        transform: scale(0.6);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 44px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 960px !important;
    }

    .hero-content {
        font-size: 46px !important;
    }

    .monthly-reports {
        top: 13vw !important;
        left: -36vw !important;
    }

    .actions {
        top: 22vw !important;
        left: -72vw !important;
    }

    .comments {
        left: 3vw !important;
    }

    .cost-analysis {
        top: 4vw !important;
        left: -14vw !important;
    }

    .statistics {
        top: 3vw !important;
        right: 1vw !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 46px !important;
        line-height: 52px !important;
    }

    .cxoTulz-content {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .feature-cloud {
        top: 260px;
    }

    .feature-lines {
        top: -86px;
        right: 102px;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 55px !important;
        padding-top: 62px !important;
        font-size: 46px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 36px !important;
        line-height: 50px !important;
        margin-left: -85px !important;
    }

    .menu-section {
        padding-left: 2% !important;
    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 290px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1499px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 840px !important;
    }

    .feature-overall-container {
        height: 876px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 143px !important;
        width: 159% !important;
        top: -143px !important;
        left: -216px !important;
        transform: scale(0.6);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 44px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .effortless-content {
        font-size: 46px !important;
        line-height: 52px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 970px !important;
    }

    .hero-content {
        font-size: 46px !important;
    }

    .monthly-reports {
        top: 13vw !important;
        left: -36vw !important;
    }

    .actions {
        top: 22vw !important;
        left: -72vw !important;
    }

    .comments {
        left: 3vw !important;
    }

    .cost-analysis {
        top: 4vw !important;
        left: -14vw !important;
    }

    .statistics {
        top: 3vw !important;
        right: 1vw !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 46px !important;
        line-height: 52px !important;
    }

    .cxoTulz-content {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .contact-details {
        margin-bottom: 40px !important;
    }

    .we-love-text {
        line-height: 56px !important;
        padding-top: 62px !important;
        font-size: 46px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 32px !important;
        line-height: 45px !important;
        margin-left: -74px !important;
    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 280px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1424px) and (max-width: 1444px) {
    .monthly-reports {
        top: 13vw !important;
        left: -36vw !important;
    }

    .actions {
        top: 22vw !important;
        left: -72vw !important;
    }

    .comments {
        left: 3vw !important;
    }

    .cost-analysis {
        top: 4vw !important;
        left: -14vw !important;
    }

    .statistics {
        top: 3vw !important;
        right: 1vw !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 280px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1423px) {
    .monthly-reports {
        top: 13vw !important;
        left: -36vw !important;
    }

    .actions {
        top: 22vw !important;
        left: -72vw !important;
    }

    .comments {
        left: 3vw !important;
    }

    .cost-analysis {
        top: 4vw !important;
        left: -14vw !important;
    }

    .statistics {
        top: 3vw !important;
        right: 1vw !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 275px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1327px) and (max-width: 1399px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 840px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 144px !important;
        width: 150% !important;
        top: -143px !important;
        left: -181px !important;
        transform: scale(0.7);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 44px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 860px !important;
    }

    .hero-content {
        font-size: 46px !important;
    }

    .statistics {
        top: 3vw !important;
        left: 2vw !important;
        width: 306px !important;
    }

    .comments {
        left: 28vw !important;
        top: 5vw;
        width: 215px !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 27vw !important;
        width: 340px !important;
    }

    .actions {
        top: 15vw !important;
        left: 5vw !important;
        width: 270px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 259px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 46px !important;
        line-height: 52px !important;
    }

    .cxoTulz-content {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .effortless-content {
        font-size: 50px !important;
    }

    .feature-lines {
        right: 102px !important;
        width: 550px;
    }

    .we-love-text {
        line-height: 62px !important;
        padding-top: 62px !important;
        font-size: 44px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 30px !important;
        line-height: 43px !important;
        padding-right: 59px !important;

    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 272px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1326px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 840px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .our-feature-width {
        width: 380px !important;
    }

    .cloud-mgt-circle {
        height: 144px !important;
        width: 150% !important;
        top: -143px !important;
        left: -165px !important;
        transform: scale(0.7);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 44px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 860px !important;
    }

    .hero-content {
        font-size: 46px !important;
    }

    .statistics {
        top: 3vw !important;
        left: 2vw !important;
        width: 306px !important;
    }

    .comments {
        left: 28vw !important;
        top: 5vw;
        width: 215px !important;
    }

    .cost-analysis {
        top: 21vw !important;
        left: 28vw !important;
        width: 309px !important;
    }

    .actions {
        top: 15vw !important;
        left: 5vw !important;
        width: 270px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 259px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 46px !important;
        line-height: 52px !important;
    }

    .cxoTulz-content {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .effortless-content {
        font-size: 50px !important;
    }

    .feature-lines {
        right: 102px !important;
        width: 550px !important;
    }

    .we-love-text {
        line-height: 62px !important;
        padding-top: 62px !important;
        font-size: 44px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 30px !important;
        line-height: 43px !important;
    }

    .hero-container-top {
        top: 256px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 270px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 151px !important;
        height: 815px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .our-feature-width {
        width: 350px !important;
    }

    .cloud-mgt-circle {
        height: 144px !important;
        width: 150% !important;
        top: -135px !important;
        left: -141px !important;
        transform: scale(0.7);
        object-fit: contain !important;
    }

    .cloud-mgt-text {
        font-size: 40px !important;
    }

    .effortlessly-content-animation {
        margin-top: -167px !important;
    }

    .under-unified-text {
        top: -177px !important;
    }

    .hero-container {
        height: 860px !important;
    }

    .hero-content {
        font-size: 46px !important;
    }

    .statistics {
        top: 3vw !important;
        left: 2vw !important;
        width: 306px !important;
    }

    .comments {
        left: 28vw !important;
        top: 5vw;
        width: 215px !important;
    }

    .cost-analysis {
        top: 23vw !important;
        left: 28vw !important;
        width: 290px !important;
    }

    .actions {
        top: 15vw !important;
        left: 5vw !important;
        width: 270px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 259px !important;
    }

    .charts-section {
        position: absolute;
    }

    .cxoTulz-content {
        font-size: 28px !important;
        line-height: 32px !important;
    }

    .actionable-content {
        font-size: 46px !important;
        line-height: 52px !important;

    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
        padding-top: 13% !important;
    }

    .effortless-content {
        font-size: 46px !important;
        line-height: 53px !important;
    }

    .feature-lines {
        right: 102px !important;
        width: 550px;
    }

    .we-love-text {
        line-height: 53px !important;
        padding-top: 62px !important;
        font-size: 46px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 120% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 28px !important;
        line-height: 40px !important;
        margin-top: -65px !important;
    }

    .hero-container-top {
        top: 258px !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .vertical-line {
        height: 250px !important;
    }

    .social-handle-section {
        margin-left: -100px !important;
    }

    .nav-list {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    }

    .nav-list.active a {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #007EA7;
        border-bottom: 2px solid #007EA7;
        padding-bottom: 1px;
        text-decoration: none !important;
    }
}

@media screen and (min-width:1260px) and (max-width:1299px) {
    .vertical-line {
        height: 260px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1500px) {
    .form-container {
        width: 36vw;
    }

    .dashboard {
        width: 59%;
        margin-top: -20%;
    }

    .cxo-charts {
        margin-top: 4%;
        margin-left: 21%;
    }

}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
    .about-container {
        overflow-y: hidden;
        top: 116px !important;
    }

    .demo-container {
        margin-top: 25vh !important;
        height: 1000px !important;
    }

    .feature-overall-container {
        height: 850px !important;
        top: 73px;
    }

    .our-feature-width {
        width: 340px !important;
    }

    .cloud-mgt-circle {
        width: 60% !important;
        left: 152px !important;
        top: -115px !important;
    }

    .cloud-mgt-text {
        font-size: 35px !important;
    }

    .effortlessly-content-animation {
        margin-top: -138px !important;
        text-align: center !important;
    }

    .under-unified-text {
        top: -125px !important;
    }

    .hero-container {
        height: 1220px !important;
    }

    .hero-content {
        font-size: 42px !important;
        text-align: center !important;
    }

    .cost-analysis {
        top: 25vw !important;
        left: 57vw !important;
        width: 383px !important;
    }

    .comments {
        left: 54vw !important;
        top: -1vw;
        width: 258px !important;
    }

    .statistics {
        top: -2vw !important;
        left: 19vw !important;
        width: 336px !important;
    }

    .actions {
        top: 15vw !important;
        left: 23vw !important;
        width: 326px !important;
    }

    .monthly-reports {
        top: 9vw !important;
        left: 36vw !important;
        width: 322px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 42px !important;
        line-height: 55px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 28px !important;
        line-height: 35px !important;
        color: #FFFFFF;
        text-align: center !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 691px !important;
    }

    .effortless-content {
        font-size: 42px !important;
        line-height: 50px !important;
        text-align: center !important;
        padding: 0 24px !important;
    }

    .feature-lines {
        right: 170px !important;
    }

    .we-love-text {
        line-height: 47px !important;
        padding-top: 52px !important;
        font-size: 42px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 26% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 120% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .streamline-text {
        font-size: 25px !important;
        line-height: 37px !important;
        margin-top: -50px !important;
        padding-right: 20px;
    }

    .hero-container-top {
        top: 222px !important;
    }

    .service-tree {
        position: relative !important;
        right: -220px !important;
    }

    .graph-charts {
        position: relative !important;
        right: 307px !important;
        top: 151px !important;
    }

    .banner-clouds {
        position: relative !important;
        right: 63px !important;
        top: -682px !important;
    }

    .inner-hero-section {
        padding-right: 92px !important;
        top: -92px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 42px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: -96px;
        left: -100px;
        height: 46vw;
        width: 500px;
    }

    .feature-lines {
        display: none !important;
    }

    .vertical-line {
        height: 206px !important;
    }

    .menu-item {
        font-size: 16px !important;
    }

    .social-handle-section {
        margin-left: -70px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }
}

@media screen and (min-width:1065px) and (max-width:1129px) {
    .vertical-line {
        height: 220px !important;
    }
}

@media screen and (min-width:1100px) and (max-width:1199px) {
    .cost-analysis {
        top: 23vw !important;
        left: 53vw !important;
        width: 383px !important;
    }
}

@media screen and (min-width:1130px) and (max-width:1199px) {
    .cloud-mgt-circle {
        left: 170px !important;
    }

    .vertical-line {
        height: 235px !important;
    }
}

@media screen and (min-width:1044px) and (max-width:1185px) {
    .hero-container {
        height: 1225px !important;
    }
}

@media screen and (min-width:1044px) and (max-width:1116px) {
    .hero-container {
        height: 1250px !important;
    }
}

@media screen and (min-width:1000px) and (max-width:1043px) {
    .hero-container {
        height: 1260px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 999px) {
    .about-container {
        overflow-y: hidden;
        top: 116px !important;
    }

    .demo-container {
        margin-top: 25vh !important;
        height: 920px !important;
    }

    .feature-overall-container {
        height: 860px !important;
        top: 73px;
    }

    .our-feature-width {
        width: 320px !important;
    }

    .cloud-mgt-circle {
        width: 63% !important;
        left: 135px !important;
        top: -113px !important;
    }

    .cloud-mgt-text {
        font-size: 35px !important;
    }

    .effortlessly-content-animation {
        margin-top: -138px !important;
        text-align: center !important;
    }

    .effortlessly-content {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .effortlessly-content-1 {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .under-unified-text {
        top: -125px !important;
    }

    .hero-container {
        height: 1230px !important;
    }

    .hero-container-top {
        top: 27vh !important;
    }

    .hero-content {
        font-size: 40px !important;
        text-align: center !important;
    }

    .cost-analysis {
        top: 31vw !important;
        left: 51vw !important;
        width: 356px !important;
    }

    .actions {
        top: 21vw !important;
        left: 18vw !important;
        width: 334px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 32vw !important;
        width: 354px !important;
    }

    .comments {
        left: 53vw !important;
        top: -2vw;
        width: 257px !important;
    }

    .statistics {
        top: -3vw !important;
        left: 14vw !important;
        width: 325px !important;
    }

    .charts-section {
        position: absolute;
    }

    .actionable-content {
        font-size: 40px !important;
        line-height: 52px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 28px !important;
        line-height: 35px !important;
        color: #FFFFFF;
        text-align: center !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 691px !important;
    }

    .effortless-content {
        font-size: 40px !important;
        text-align: center !important;
        line-height: 54px !important;
    }

    .under-unified-text {
        top: -132px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        width: 72% !important;
        left: 109px !important;
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .graph-charts {
        top: -284px !important;
    }

    .about-right-content {
        width: 85%;
    }

    .about-left-section {
        height: 672px !important;
    }

    .effortless-content-1 {
        font-size: 48px !important;
        line-height: 67px !important;
        text-align: center !important;
    }

    .feature-lines {
        right: 170px !important;
    }

    .we-love-text {
        line-height: 52px !important;
        padding-top: 52px !important;
        font-size: 40px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 166% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px;
    }

    .streamline-text {
        font-size: 25px !important;
        line-height: 35px !important;
        margin-top: -40px !important;
        margin-left: -50px !important;
    }

    .service-tree {
        position: relative !important;
        right: -220px !important;
    }

    .graph-charts {
        position: relative !important;
        right: 307px !important;
        top: 151px !important;
    }

    .banner-clouds {
        position: relative !important;
        right: 63px !important;
        top: -668px !important;
    }

    .inner-hero-section {
        text-align: center !important;
        top: -92px !important;
        padding-right: 51px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 40px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-mobile-view {
        display: none !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: -78px;
        left: -56px;
        width: 412px;
    }

    .feature-lines {
        display: none !important;
    }

    .menu-item {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .vertical-line {
        height: 208px !important;
    }

    .social-handle-section {
        margin-left: -60px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }
}

@media screen and (min-width:767px) and (max-width:767px) {
    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 295px !important;
    }

    .actions {
        top: 24vw !important;
        left: 5vw !important;
        width: 270px !important;
    }

    .comments {
        left: 46vw !important;
        top: -3vw;
        width: 240px !important;
    }

    .cost-analysis {
        top: 33vw !important;
        left: 45vw !important;
        width: 290px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 306px !important;
    }

    .about-left-section {
        padding-left: 8% !important;
        height: 500px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 15vh !important;
        height: 970px !important;
    }

    .feature-overall-container {
        height: 826px !important;
    }

    .responsive-width {
        width: 52%;
    }

    .our-feature-width {
        width: 300px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 40px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1400px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 295px !important;
    }

    .actions {
        top: 21vw !important;
        left: 6vw !important;
        width: 279px !important;
    }

    .comments {
        left: 46vw !important;
        top: -3vw;
        width: 240px !important;
    }

    .cost-analysis {
        top: 31vw !important;
        left: 45vw !important;
        width: 290px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 306px !important;
    }

    .charts-section {
        position: absolute;
    }

    .graph-charts {
        bottom: -89px !important;
    }

    .effortless-content {
        font-size: 48px !important;
        line-height: 67px !important;
        text-align: center !important;
    }

    .service-tree {
        top: 70px;
    }

    .graph-charts {
        top: -219px !important;
    }

    .banner-clouds {
        top: -878px !important;
    }

    .effortless-content {
        font-size: 40px !important;
        line-height: 52px !important;
    }

    .effortless-content-1 {
        font-size: 40px !important;
        text-align: center;
    }

    .feature-lines {
        right: 90px !important;
        height: 322px !important;
        top: 23px !important;
    }

    .feature-cloud {
        position: relative;
        top: 260px;
        z-index: 9;
    }

    .we-love-text {
        line-height: 48px !important;
        padding-top: 52px !important;
        font-size: 40px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 50% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 14px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 166% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 55px !important;
        padding-bottom: 9px !important;
    }

    .streamline-text {
        font-size: 24px !important;
        margin-top: -34px !important;
        line-height: 30px !important;
        margin-left: -42px !important;
    }

    .actionable-content {
        font-size: 40px !important;
        line-height: 52px !important;
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 72% !important;
        left: -10px !important;
        top: -110px !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .inner-hero-section {
        padding-right: 50px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 40px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 34px !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 34px !important;

    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 490px !important;
        padding-left: 10% !important;
        padding-top: 2%;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 100% !important;
        margin-left: 188px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 190% !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: -24px;
        left: -29px;
        height: 406px;
        width: 280px !important;
    }

    .feature-lines {
        display: none !important;
    }

    .hero-container-top {
        top: 15vh !important;
    }

    .feature-subheading {
        font-size: 24px !important;
        line-height: 32.15px !important;
    }

    .feature-description {
        font-size: 21px !important;
        line-height: 29.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .social-handle-section {
        margin-left: -30px !important;
    }

    .vertical-line {
        height: 210px !important;
    }

    .streamline-inner-box {
        margin-top: 14px !important;
    }

    .streamline-inner-box {
        .cxotulz-logo {
            max-width: none !important;
            width: 190px !important;
            padding: 0 5px;
        }
    }

    .menu-item {
        font-size: 15px !important;
        line-height: 19px !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -2px !important;
        text-align: left !important;
    }

    .our-feature-role {
        width: 170px !important;
    }

    .schedule-btn {
        font-size: 14px !important;
        line-height: 22px !important;
        padding: 12px 18px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }
}

@media screen and (min-width:768px) and (max-width:926px) {
    .schedule-demo-mobile-view {
        width: 71px !important;
    }
}

@media screen and (min-width:850px) and (max-width:991px) {
    .cost-analysis {
        top: 28vw !important;
        left: 42vw !important;
        width: 303px !important;
    }

    .about-right-section {
        padding-bottom: 35px !important;
    }

    .actions {
        top: 17vw !important;
        left: 8vw !important;
        width: 289px !important;
    }

    .comments {
        left: 42vw !important;
        top: -4vw;
        width: 240px !important;
    }
}

@media screen and (min-width:991px) and (max-width:991px) {
    .hero-container {
        height: 1375px !important;
    }

    .graph-charts {
        top: 220px !important;
        right: 308px !important;
    }

    .service-tree {
        right: -211px !important;
    }

    .banner-clouds {
        right: 53px !important;
        top: -610px !important;
    }
}

@media screen and (min-width:986px) and (max-width:990px) {
    .graph-charts {
        right: -195px !important;
    }

    .banner-clouds {
        top: -641px !important;
        right: 277px !important;
    }
}

@media screen and (min-width:768px) and (max-width:773px) {
    .hero-container {
        height: 1400px !important;
    }
}

@media screen and (min-width:774px) and (max-width:802px) {
    .hero-container {
        height: 1400px !important;
    }
}

@media screen and (min-width:803px) and (max-width:942px) {
    .hero-container {
        height: 1400px !important;
    }
}

@media screen and (min-width:803px) and (max-width:854px) {
    .hero-container {
        height: 1400px !important;
    }
}

@media screen and (min-width:828px) and (max-width:854px) {
    .hero-container {
        height: 1405px !important;
    }
}

@media screen and (min-width:855px) and (max-width:885px) {
    .hero-container {
        height: 1400px !important;
    }
}

@media screen and (min-width:886px) and (max-width:942px) {
    .hero-container {
        height: 1365px !important;
    }
}

@media screen and (min-width:943px) and (max-width:990px) {
    .hero-container {
        height: 1365px !important;
    }
}

@media screen and (min-width:992px) and (max-width:999px) {
    .hero-container {
        height: 1278px !important;
    }

    .graph-charts {
        top: 151px !important;
    }
}

@media screen and (min-width:768px) {
    .schedule-button-section {
        display: none !important;
    }
}

@media only screen and (min-width: 712px) and (max-width: 767px) {
    .hero-container {
        height: 1580px !important;
    }
}

@media only screen and (min-width: 722px) and (max-width: 767px) {
    .about-container {
        overflow-y: hidden;
    }

    .streamline-box {
        padding-top: 9px !important;
    }

    .demo-container {
        margin-top: 0vh !important;
        height: 970px !important;
    }

    .feature-overall-container {
        height: 790px !important;
    }

    .responsive-width {
        width: 52%;
    }

    .our-feature-width {
        width: 300px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 40px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1479px !important;
    }

    .hero-container-top {
        top: 15vh !important;
    }

    .cost-analysis {
        top: 34vw !important;
        left: 42vw !important;
        width: 309px !important;
    }

    .actions {
        top: 21vw !important;
        left: -1vw !important;
        width: 318px !important;
    }

    .monthly-reports {
        top: 9vw !important;
        left: 17vw !important;
        width: 324px !important;
    }

    .comments {
        left: 48vw !important;
        top: -3vw;
        width: 257px !important;
    }

    .statistics {
        top: -4vw !important;
        left: -4vw !important;
        width: 309px !important;
    }

    .graph-charts {
        bottom: -89px !important;
    }

    .effortlessly-content {
        font-size: 28px !important;
    }

    .effortlessly-content-1 {
        font-size: 28px !important;
    }

    .effortless-content {
        font-size: 40px !important;
        line-height: 50px !important;
    }

    .service-tree {
        top: 70px;
    }

    .graph-charts {
        top: -219px !important;
    }

    .banner-clouds {
        top: -878px !important;
    }

    .feature-lines {
        right: 128px !important;
        height: 322px !important;
        top: -17px !important;
    }

    .feature-cloud {
        top: 200px !important;
    }

    .we-love-text {
        line-height: 46px !important;
        padding-top: 52px !important;
        font-size: 40px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 190% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 16px !important;
    }

    .actionable-content {
        font-size: 48px !important;
        line-height: 50px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .inner-hero-section {
        padding-right: 50px !important;
        padding-left: 1vw !important;
        text-align: center !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 84% !important;
        left: -7px !important;
        top: -113px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 40px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 500px !important;
        padding-left: 9% !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 102% !important;
        margin-left: 180px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 216% !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: 16px;
        left: -27px;
        height: 269px;
    }

    .feature-lines {
        display: none !important;
    }

    .streamline-container {
        display: none !important;
    }

    .streamline-inner-box {
        margin-top: 20px !important;
    }

    .social-handle-section {
        margin-top: 50px !important;
        margin-left: 0px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        line-height: 28px !important;
        padding-left: 30px !important;

    }

    .feature-subheading {
        font-size: 22px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 20px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }
}

@media only screen and (min-width: 722px) and (max-width: 752px) {
    .hero-container {
        height: 1527px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 767px) {
    .hero-container {
        height: 1450px !important;
    }
}

@media only screen and (min-width: 712px) and (max-width: 721px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 0vh !important;
        height: 955px !important;
    }

    .feature-overall-container {
        height: 790px !important;
    }

    .responsive-width {
        width: 52%;
    }

    .our-feature-width {
        width: 280px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 36px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1510px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 274px !important;
    }

    .actions {
        top: 20vw !important;
        left: 4vw !important;
        width: 279px !important;
    }

    .comments {
        left: 47vw !important;
        top: -3vw;
        width: 222px !important;
    }

    .cost-analysis {
        top: 33vw !important;
        left: 42vw !important;
        width: 279px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 282px !important;
    }

    .hero-content {
        font-size: 48px !important;
    }

    .graph-charts {
        bottom: -89px !important;
    }

    .effortless-content {
        font-size: 36px !important;
        line-height: 54px !important;
    }

    .effortless-content-1 {
        font-size: 36px !important;
        line-height: 54px !important;
    }

    .service-tree {
        top: 70px;
    }

    .graph-charts {
        top: -219px !important;
    }

    .banner-clouds {
        top: -878px !important;
    }

    .feature-lines {
        right: 128px !important;
        height: 322px !important;
        top: -17px !important;
    }

    .feature-cloud {
        top: 200px !important;
    }

    .we-love-text {
        line-height: 41px !important;
        padding-top: 52px !important;
        font-size: 36px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-div-btn {
        width: 20% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 190% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 16px !important;
    }

    .actionable-content {
        font-size: 36px !important;
        line-height: 50px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .inner-hero-section {
        padding-right: 40px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 95% !important;
        left: -3px !important;
        top: -120px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 36px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 500px !important;
        padding-left: 8% !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 102% !important;
        margin-left: 180px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 216% !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: 16px;
        left: -27px;
        height: 269px;
    }

    .feature-lines {
        display: none !important;
    }

    .streamline-container {
        display: none !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        line-height: 29px !important;
        padding-right: 221px !important;
        padding-left: 28px !important;
    }

    .feature-subheading {
        font-size: 22px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 20px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .our-role-width {
        width: 180px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }

    .streamline-box {
        padding-top: 11px !important;
    }
}

@media only screen and (min-width: 617px) and (max-width: 711px) {
    .about-container {
        overflow-y: hidden;
    }

    .streamline-box {
        padding-top: 13px !important;
    }

    .demo-container {
        margin-top: 0vh !important;
        height: 945px !important;
    }

    .feature-overall-container {
        height: 790px !important;
    }

    .responsive-width {
        width: 52%;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-circle {
        top: -123px !important;
    }

    .cloud-mgt-text {
        font-size: 48px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 36px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1630px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 274px !important;
    }

    .actions {
        top: 22vw !important;
        left: 3vw !important;
        width: 260px !important;
    }

    .comments {
        left: 50vw !important;
        top: -3vw;
        width: 210px !important;
    }

    .cost-analysis {
        top: 35vw !important;
        left: 44vw !important;
        width: 264px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 270px !important;
    }

    .graph-charts {
        bottom: -89px !important;
    }

    .effortless-content {
        font-size: 36px !important;
        line-height: 54px !important;
    }

    .service-tree {
        top: 70px;
    }

    .graph-charts {
        top: -219px !important;
    }

    .banner-clouds {
        top: -878px !important;
    }

    .feature-lines {
        right: 128px !important;
        height: 322px !important;
        top: -17px !important;
    }

    .feature-cloud {
        top: 200px !important;
    }

    .we-love-text {
        line-height: 42px !important;
        padding-top: 52px !important;
        font-size: 36px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 218% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 14px !important;
    }

    .actionable-content {
        font-size: 36px !important;
        line-height: 50px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 26px !important;
        line-height: 33px !important;

    }

    .inner-hero-section {
        padding-right: 42px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 89% !important;
        left: -12px !important;
        top: -108px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 36px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 470px !important;
        padding-left: 8% !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 102% !important;
        margin-left: 147px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 201% !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -5px !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: 16px;
        left: -27px;
        height: 269px;
    }

    .feature-lines {
        display: none !important;
    }

    .streamline-container {
        display: none !important;
    }

    .social-handle-section {
        margin-top: 50px !important;
        margin-left: 0px !important;
    }

    .menu-heading {
        font-size: 18px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        padding-right: 132px !important;
        padding-left: 20px !important;
        line-height: 29px;
    }

    .streamline-inner-box {
        .cxotulz-logo {
            max-width: none !important;
            width: 180px !important;
            padding: 0 5px;
        }
    }

    .feature-subheading {
        font-size: 22px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 20px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .our-role-width {
        width: 180px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }

    .follow-us-text {
        padding-top: 1px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 616px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 0vh !important;
        height: 965px !important;
    }

    .feature-overall-container {
        height: 790px !important;
    }

    .responsive-width {
        width: 52%;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 44px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 42px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1647px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 274px !important;
    }

    .actions {
        top: 25vw !important;
        left: 2vw !important;
        width: 250px !important;
    }

    .comments {
        left: 55vw !important;
        top: -3vw;
        width: 188px !important;
    }

    .cost-analysis {
        top: 39vw !important;
        left: 46vw !important;
        width: 255px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 270px !important;
    }

    .effortless-content {
        font-size: 36px !important;
        line-height: 46px !important;
    }

    .service-tree {
        top: 70px;
    }

    .graph-charts {
        top: -219px !important;
    }

    .banner-clouds {
        top: -878px !important;
    }

    .feature-lines {
        right: 128px !important;
        height: 322px !important;
        top: -17px !important;
    }

    .feature-cloud {
        top: 200px !important;
    }

    .we-love-text {
        line-height: 42px !important;
        padding-top: 52px !important;
        font-size: 36px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 283% !important;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
        margin-right: 20px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 14px !important;
    }

    .actionable-content {
        font-size: 26px !important;
        line-height: 40px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .inner-hero-section {
        padding-right: 35px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 95% !important;
        left: -3px !important;
        top: -104px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 36px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 450px !important;
        padding-left: 5% !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 102% !important;
        margin-left: 146px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 216% !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -5px !important;
    }

    .close-feature-lines {
        display: block !important;
        position: relative;
        top: 16px;
        left: -27px;
        height: 269px;
    }

    .feature-lines {
        display: none !important;
    }

    .streamline-container {
        display: none !important;
    }

    .social-handle-section {
        margin-top: 50px !important;
        margin-left: 0px !important;
    }

    .menu-heading {
        font-size: 17px !important;
    }

    .social-handle-section {
        margin-top: 45px !important;
        margin-left: 0px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        padding-right: 91px !important;
        padding-left: 14px !important;
        line-height: 29px !important;
    }

    .feature-subheading {
        font-size: 22px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 20px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .streamline-inner-box {
        margin-top: 25px !important;
    }

    .streamline-inner-box {
        .cxotulz-logo {
            max-width: none !important;
            width: 160px !important;
            padding: 0 5px;
        }
    }

    .our-role-width {
        width: 180px !important;
    }

    .our-feature-role {
        width: 170px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }

    .follow-us-text {
        padding-top: 5px !important;
    }
}

@media only screen and (min-width: 536px) and (max-width: 575px) {
    .hero-container {
        height: 1570px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 581px) {
    .hero-container {
        height: 1570px !important;
    }
}

@media only screen and (min-width: 582px) and (max-width: 617px) {
    .hero-container {
        height: 1515px !important;
    }
}

@media only screen and (min-width: 582px) and (max-width: 583px) {
    .hero-container {
        height: 1585px !important;
    }
}

@media only screen and (min-width: 584px) and (max-width: 616px) {
    .hero-container {
        height: 1585px !important;
    }
}

@media only screen and (min-width: 618px) and (max-width: 626px) {
    .hero-container {
        height: 1575px !important;
    }
}

@media only screen and (min-width: 627px) and (max-width: 630px) {
    .hero-container {
        height: 1550px !important;
    }
}

@media only screen and (min-width: 630px) and (max-width: 660px) {
    .hero-container {
        height: 1535px !important;
    }
}

@media only screen and (min-width: 661px) and (max-width: 705px) {
    .hero-container {
        height: 1485px !important;
    }
}

@media only screen and (min-width: 706px) and (max-width: 711px) {
    .hero-container {
        height: 1456px !important;
    }
}

@media only screen and (min-width: 536px) and (max-width: 575px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 28vh !important;
        height: 940px !important;
    }

    .feature-overall-container {
        height: 892px !important;
    }

    .responsive-width {
        width: 83%;
    }

    .contact-us-btn {
        width: 134% !important;
    }

    .our-feature-width {
        width: 280px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 34px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 36px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1640px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 274px !important;
    }

    .actions {
        top: 27vw !important;
        left: 1vw !important;
        width: 250px !important;
    }

    .comments {
        left: 55vw !important;
        top: -3vw;
        width: 188px !important;
    }

    .cost-analysis {
        top: 41vw !important;
        left: 49vw !important;
        width: 248px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 270px !important;
    }

    .effortlessly-content {
        font-size: 28px !important;
    }

    .effortlessly-content-1 {
        font-size: 28px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -7px !important;
    }

    .graph-charts {
        top: -219px !important;
        right: 30px !important;
    }

    .banner-clouds {
        top: -862px !important;
        right: -11px !important;
    }

    .feature-cloud {
        display: none;
    }

    .responsive-cloud {
        display: block !important;
        height: 180px !important;
    }

    .feature-lines {
        display: none;
    }

    .responsive-feature-lines {
        display: block !important;
    }

    .unlock-responsive-content {
        font-size: 36px !important;
        line-height: 42px !important;
    }

    .effortless-content {
        font-size: 36px !important;
        line-height: 50px !important;
    }

    .feature-container {
        margin-left: -25px !important;
    }

    .responsive-feature-lines {
        top: -5px !important;
        left: -76px !important;
        position: relative;
        z-index: -1;
        height: 545px !important;
    }

    .we-love-text {
        line-height: 42px !important;
        padding-top: 52px !important;
        font-size: 36px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: center !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 159% !important;
        margin-left: -66px;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 78% !important;
        margin-right: 79px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 16px !important;
    }

    .actionable-content {
        font-size: 36px !important;
        line-height: 42px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .inner-hero-section {
        padding-right: 34px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 100% !important;
        left: -10px !important;
        top: -112px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 40px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 430px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 55% !important;
        margin-left: 116px !important;
    }

    .mobile-view-contact-input-box {
        padding: 16px;
        width: 196% !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -3px !important;
    }

    .mobile-view-contact {
        margin-top: -80px !important;
    }

    .contact-bottom-cloud {
        margin-bottom: -23px !important;
    }

    .contact-details {
        margin-bottom: 0px !important;
    }

    .feature-cloud-mobile {
        display: block;
    }

    .responsive-cloud {
        display: none;
    }

    .left-feature {
        margin-top: 50px !important;
    }

    .right-feature {
        margin-top: 50px !important;
    }

    .streamline-container {
        display: none !important;
    }

    .streamline-inner-box {
        margin-top: 30px !important;
    }

    .follow-us-text {
        margin-left: -27px !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .social-handle-section {
        margin-top: 45px !important;
        margin-left: 0px !important;
    }

    .streamline-text-mobile {
        font-size: 24px !important;
        padding-right: 94px !important;
        padding-left: 26px !important;
        line-height: 30px !important;
    }

    .feature-subheading {
        font-size: 22px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 20px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 15px !important;
    }

    .our-role-width {
        width: 180px !important;
    }

    .nav-horizontal-line {
        width: 90% !important;
    }
}

@media only screen and (min-width: 565px) and (max-width: 575px) {
    .hero-container {
        height: 1605px !important;
    }

    .our-feature-role {
        width: 170px !important;
    }
}

@media only screen and (min-width: 470px) and (max-width: 535px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 35vh !important;
        height: 940px !important;
    }

    .feature-overall-container {
        height: 780px !important;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 34px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 34px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1730px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 21vw !important;
        width: 274px !important;
    }

    .actions {
        top: 26vw !important;
        left: 1vw !important;
        width: 250px !important;
    }

    .comments {
        left: 55vw !important;
        top: -3vw;
        width: 188px !important;
    }

    .cost-analysis {
        top: 43vw !important;
        left: 49vw !important;
        width: 248px !important;
    }

    .statistics {
        top: -5vw !important;
        left: 1vw !important;
        width: 270px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -7px !important;
    }

    .graph-charts {
        top: -219px !important;
        right: 13px !important;
    }

    .banner-clouds {
        top: -862px !important;
        right: -11px !important;
    }

    .feature-cloud {
        display: none;
    }

    .responsive-cloud {
        display: block !important;
        height: 180px !important;
    }

    .feature-lines {
        display: none;
    }

    .responsive-feature-lines {
        display: block !important;
    }

    .effortless-content {
        font-size: 34px !important;
        line-height: 40px !important;
    }

    .effortless-content-1 {
        font-size: 34px !important;
        line-height: 40px !important;
    }

    .feature-container {
        margin-left: -25px !important;
    }

    .responsive-feature-lines {
        top: -6px !important;
        left: -76px !important;
        position: relative;
        z-index: -1;
        height: 511px !important;
    }

    .we-love-text {
        line-height: 41px !important;
        padding-top: 52px !important;
        font-size: 34px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: center !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 159% !important;
        margin-left: -66px;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 78% !important;
        margin-right: 79px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .menu-item {
        font-size: 14px !important;
    }

    .actionable-content {
        font-size: 34px !important;
        line-height: 40px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .inner-hero-section {
        padding-right: 38px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 104% !important;
        left: -15px !important;
        top: -102px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 34px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 400px !important;
    }

    .cxo-logo {
        margin-left: 7px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 216% !important;
        margin-left: -25px !important;
        font-size: 12px !important;
    }

    .mobile-view-contact-input-box {
        padding: 14px;
        width: 80% !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -2px !important;
    }

    .mobile-view-contact {
        margin-top: -80px !important;
    }

    .contact-bottom-cloud {
        margin-bottom: -23px !important;
    }

    .contact-details {
        margin-bottom: 0px !important;
    }

    .left-feature {
        margin-top: 50px !important;
    }

    .right-feature {
        margin-top: 50px !important;
    }

    .streamline-container {
        display: none !important;
    }

    .social-handle-section {
        margin-top: 45px;
        margin-left: -66px;
    }

    .powered-by-text {
        font-size: 15px !important;
    }

    .streamline-inner-box {
        margin-top: 45px !important;
    }

    .social-handle-section {
        margin-top: 45px !important;
        margin-left: 0px !important;
    }

    .follow-us-text {
        margin-left: -26px !important;
        padding-top: 5px !important;
    }

    .menu-heading {
        font-size: 17px !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        padding-right: 63px !important;
        padding-left: 11px !important;
        line-height: 29px !important;
    }

    .feature-subheading {
        font-size: 20px !important;
        line-height: 29.15px !important;
    }

    .feature-description {
        font-size: 18px !important;
        line-height: 27.68px !important;
    }

    .feature-content-section {
        padding: 12px !important;
    }

    .our-role-width {
        width: 180px !important;
    }

    .nav-horizontal-line {
        width: 95% !important;
    }

    .mobile-menu-link {
        grid-column: 1/6 !important;
        top: 77% !important;
        padding-left: 2rem !important;
        height: 100vh !important;
    }

    .responsive-cloud {
        display: none !important;
    }

    .feature-cloud-mobile {
        display: block !important;
        width: 200px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 519px) {
    .hero-container {
        height: 1630px !important;
    }
}

@media only screen and (min-width: 520px) and (max-width: 533px) {
    .hero-container {
        height: 1635px !important;
    }
}

@media only screen and (min-width: 533px) and (max-width: 535px) {
    .hero-container {
        height: 1605px !important;
    }
}

@media only screen and (min-width: 450px) and (max-width: 471px) {
    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 21vh !important;
        height: 950px !important;
    }

    .feature-overall-container {
        height: 875px !important;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .streamline-text {
        font-size: 44px !important;
        line-height: 50px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 34px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 34px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1675px !important;
    }

    .monthly-reports {
        top: 13vw !important;
        left: 25vw !important;
        width: 259px !important;
    }

    .actions {
        top: 28vw !important;
        left: 1vw !important;
        width: 250px !important;
    }

    .comments {
        left: 57vw !important;
        top: -3vw;
        width: 168px !important;
    }

    .cost-analysis {
        top: 48vw !important;
        left: 51vw !important;
        width: 216px !important;
    }

    .statistics {
        top: -5vw !important;
        right: 34vw !important;
        width: 270px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -7px !important;
    }

    .graph-charts {
        top: -197px !important;
        right: 13px !important;
    }

    .banner-clouds {
        top: -862px !important;
        right: -11px !important;
    }

    .feature-cloud {
        display: none;
    }

    .responsive-cloud {
        display: block !important;
        height: 180px !important;
    }

    .feature-lines {
        display: none;
    }

    .responsive-feature-lines {
        display: block !important;
    }

    .effortless-content {
        font-size: 34px !important;
        line-height: 40px !important;
    }

    .feature-container {
        margin-left: -25px !important;
    }

    .responsive-feature-lines {
        top: -5px !important;
        left: -76px !important;
        position: relative;
        z-index: -1;
        height: 545px !important;
    }

    .we-love-text {
        line-height: 40px !important;
        padding-top: 52px !important;
        font-size: 34px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: center !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 159% !important;
        margin-left: -66px;
    }

    .contact-input {
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 78% !important;
        margin-right: 79px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 18px !important;
        line-height: 28px !important;
    }

    .menu-item {
        font-size: 16px !important;
    }

    .actionable-content {
        font-size: 34px !important;
        line-height: 40px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 26px !important;
        line-height: 32px !important;
    }

    .inner-hero-section {
        padding-right: 35px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 104% !important;
        left: -15px !important;
        top: -102px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 33px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 400px !important;
    }

    .cxo-logo {
        margin-left: 20px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 216% !important;
        margin-left: -25px !important;
        font-size: 12px !important;
    }

    .mobile-view-contact-input-box {
        padding: 14px;
        width: 80% !important;
    }

    .mobile-view-contact {
        margin-top: -80px !important;
    }

    .contact-bottom-cloud {
        margin-bottom: -23px !important;
    }

    .contact-details {
        margin-bottom: 0px !important;
    }

    .left-feature {
        margin-top: 50px !important;
    }

    .right-feature {
        margin-top: 50px !important;
    }

    .streamline-container {
        display: none !important;
    }

    .powered-by-text {
        font-size: 15px !important;
    }

    .social-handle-section {
        margin-left: 0px !important;
    }

    .menu-heading {
        font-size: 16px !important;
    }

    .streamline-inner-box {
        margin-top: 32px !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .menu-item {
        font-size: 14px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        padding-right: 33px !important;
        padding-left: 5px !important;
        line-height: 32px !important;
    }

    .feature-subheading {
        font-size: 20px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 18px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 14px !important;
    }

    .follow-us-text {
        margin-left: -24px !important;
        padding-top: 5px !important;
    }

    .our-role-width {
        width: 180px !important;
    }

    .nav-horizontal-line {
        width: 100% !important;
    }

    .mobile-menu-link {
        grid-column: 1/6 !important;
        top: 77% !important;
        padding-left: 2rem !important;
        height: 100vh !important;
    }

    .feature-cloud-mobile {
        display: block !important;
        width: 200px !important;
    }

    .responsive-cloud {
        display: none !important;
    }
}

@media only screen and (min-width: 492px) and (max-width: 500px) {
    .hero-container {
        height: 1605px !important;
    }
}

@media only screen and (min-width: 420px) and (max-width: 449px) {
    .mobile-menu-link {
        grid-column: 1/6 !important;
        top: 77% !important;
        padding-left: 2rem !important;
        height: 100vh !important;
    }

    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 15vh !important;
        height: 940px !important;
    }

    .feature-overall-container {
        height: 850px !important;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .streamline-text {
        font-size: 22px !important;
        line-height: 27px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 30px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 34px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1590px !important;
    }

    .monthly-reports {
        top: 13vw !important;
        left: 25vw !important;
        width: 259px !important;
    }

    .actions {
        top: 28vw !important;
        left: 1vw !important;
        width: 236px !important;
    }

    .comments {
        left: 57vw !important;
        top: -3vw;
        width: 168px !important;
    }

    .cost-analysis {
        top: 51vw !important;
        left: 54vw !important;
        width: 191px !important;
    }

    .statistics {
        top: -5vw !important;
        right: 34vw !important;
        width: 270px !important;
    }

    .effortlessly-content {
        font-size: 22px !important;
    }

    .effortlessly-content-1 {
        font-size: 22px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -7px !important;
    }

    .graph-charts {
        top: -172px !important;
        right: 13px !important;
    }

    .banner-clouds {
        top: -698px !important;
        right: -11px !important;
    }

    .feature-cloud {
        display: none;
    }

    .responsive-cloud {
        display: block !important;
        height: 180px !important;
    }

    .feature-lines {
        display: none;
    }

    .responsive-feature-lines {
        display: block !important;
    }

    .effortless-content {
        font-size: 29px !important;
        line-height: 42px !important;
    }

    .effortless-content-1 {
        font-size: 29px !important;
        line-height: 42px !important;
    }

    .feature-container {
        margin-left: -25px !important;
    }

    .responsive-feature-lines {
        top: -1px !important;
        left: -75px !important;
        position: relative;
        z-index: -1;
    }

    .we-love-text {
        line-height: 40px !important;
        padding-top: 52px !important;
        font-size: 34px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: center !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-btn {
        width: 164% !important;
        margin-left: -22px !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 40px !important;
        padding-bottom: 40px !important;
    }

    .streamline-text {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .menu-item {
        font-size: 13px !important;
    }

    .actionable-content {
        font-size: 34px !important;
        line-height: 42px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .inner-hero-section {
        padding-right: 35px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 103% !important;
        left: -10px !important;
        top: -95px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 31px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 400px !important;
    }

    .cxo-logo {
        margin-left: 16px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 267% !important;
        margin-left: -34px !important;
        font-size: 12px !important;
    }

    .mobile-view-contact-input-box {
        padding: 14px;
        width: 74% !important;
    }

    .mobile-view-contact {
        margin-top: -80px !important;
    }

    .contact-bottom-cloud {
        margin-bottom: -23px !important;
    }

    .contact-details {
        margin-bottom: 0px !important;
    }

    .mobile-contact-view {
        font-size: 13px !important;
        line-height: 14px !important;
        padding: 14px !important;
        width: 87% !important;
        margin-right: 20px !important;
    }

    .mobile-contact-button {
        width: 132% !important;
        margin-left: -22px !important;
        padding: 13px !important;
        font-size: 14px !important;
    }

    .left-feature {
        margin-top: 50px !important;
    }

    .right-feature {
        margin-top: 50px !important;
    }

    .feature-cloud-mobile {
        display: block;
    }

    .responsive-cloud {
        display: none;
    }

    .streamline-container {
        display: none !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .social-handle-section {
        margin-left: -21px !important;
    }

    .streamline-inner-box {
        margin-top: 40px !important;
    }

    .streamline-text-mobile {
        font-size: 21px !important;
        padding-right: 33px !important;
        padding-left: 1px !important;
        line-height: 27px !important;
    }

    .feature-subheading {
        font-size: 20px !important;
        line-height: 30.15px !important;
    }

    .feature-description {
        font-size: 18px !important;
        line-height: 28.68px !important;
    }

    .feature-content-section {
        padding: 13px !important;
    }

    .powered-by-text {
        font-size: 16px !important;
    }

    .menu-heading {
        font-size: 16px !important;
    }

    .follow-us-text {
        margin-left: -16px !important;
        padding-top: 5px !important;
    }

    .our-role-width {
        width: 160px !important;
    }

    .feature-cloud-mobile {
        display: block !important;
        width: 200px !important;
    }

    .responsive-cloud {
        display: none !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
    .nav-horizontal-line {
        width: 121% !important;
    }

    .mobile-menu-link {
        grid-column: 1/6 !important;
        top: 77% !important;
        padding-left: 2rem !important;
        height: 100vh !important;
    }

    .hero-container-top {
        top: 15vh !important;
    }

    .about-container {
        overflow-y: hidden;
    }

    .demo-container {
        margin-top: 5vh !important;
        height: 900px !important;
    }

    .feature-overall-container {
        height: 810px !important;
    }

    .our-feature-width {
        width: 260px !important;
    }

    .App {
        height: 150vh !important;
    }

    .cloud-mgt-text {
        font-size: 22px !important;
    }

    .cxo-dashboard {
        display: none !important;
    }

    .cxo-charts {
        display: none !important;
    }

    .dashboard {
        display: none !important;
    }

    .hero-content {
        font-size: 28px !important;
        text-align: center !important;
    }

    .hero-container {
        height: 1530px !important;
    }

    .monthly-reports {
        top: 13vw !important;
        left: 25vw !important;
        width: 193px !important;
    }

    .actions {
        top: 28vw !important;
        left: 1vw !important;
        width: 189px !important;
    }

    .comments {
        left: 57vw !important;
        top: -3vw;
        width: 122px !important;
    }

    .cost-analysis {
        top: 51vw !important;
        left: 54vw !important;
        width: 134px !important;
    }

    .statistics {
        top: -5vw !important;
        right: 34vw !important;
        width: 200px !important;
    }

    .effortlessly-content {
        font-size: 22px !important;
    }

    .effortlessly-content-1 {
        font-size: 22px !important;
        margin-bottom: 20px !important;
    }

    .service-tree {
        top: 70px !important;
        right: -7px !important;
    }

    .graph-charts {
        top: -112px !important;
        right: 13px !important;
    }

    .banner-clouds {
        top: -536px !important;
        right: 4px !important;
    }

    .feature-cloud {
        display: none;
    }

    .responsive-cloud {
        display: block !important;
        height: 130px !important;
    }

    .feature-lines {
        display: none;
    }

    .responsive-feature-lines {
        display: block !important;
    }

    .effortless-content {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .feature-container {
        margin-left: -25px !important;
    }

    .responsive-feature-lines {
        top: -25px !important;
        left: -75px !important;
        position: relative;
        z-index: -1;
    }

    .we-love-text {
        line-height: 35px !important;
        padding-top: 52px !important;
        font-size: 28px !important;
    }

    .contact-box {
        display: flex !important;
        justify-content: center !important;
    }

    .contact-div {
        width: 68% !important;
    }

    .contact-us-box {
        margin-top: 0px !important;
    }

    .footer-menu {
        padding-left: 50px !important;
        padding-bottom: 40px !important;
    }

    .responsive-width {
        width: 83%;
    }

    .actionable-content {
        font-size: 28px !important;
        line-height: 33px !important;
        text-align: center !important;
    }

    .cxoTulz-content {
        text-align: center !important;
        font-size: 22px !important;
        line-height: 27px !important;
        margin-bottom: 0 !important;
    }

    .inner-hero-section {
        padding-right: 25px !important;
        padding-left: 1vw !important;
    }

    .responsive-button-align {
        display: flex;
        justify-content: center;
    }

    .cloud-management {
        text-align: center !important;
    }

    .cloud-mgt-circle {
        width: 103% !important;
        left: -10px !important;
        top: -82px !important;
    }

    .cloud-mgt {
        text-align: center !important;
        font-size: 22px !important;
    }

    .effortlessly-content-animation {
        text-align: center !important;
    }

    .effortlessly-content {
        text-align: center !important;
    }

    .effortlessly-content-1 {
        text-align: center !important;
    }

    .cloud-circle {
        display: block !important;
        margin: 0 auto !important;
    }

    .about-left-section {
        height: 300px !important;
    }

    .cxo-logo {
        margin-left: 60px !important;
        width: 140px !important;
    }

    .streamline-text {
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .streamline-box {
        padding-top: 23px !important;
    }

    .contact-input-box {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .schedule-button-section {
        display: flex !important;
        justify-content: center !important;
    }

    .handle-desktop-view {
        visibility: hidden;
    }

    .mob-view-footer {
        position: relative;
        top: 40px;
    }

    .mobile-view-contact-btn {
        width: 364% !important;
        margin-left: -30px !important;
        font-size: 12px !important;
    }

    .mobile-view-contact-input-box {
        padding: 14px;
        width: 74% !important;
    }

    .mobile-view-contact {
        margin-top: -80px !important;
    }

    .mobile-contact-button {
        width: 160% !important;
        margin-left: -34px !important;
        padding: 11px !important;
        font-size: 13px !important;
    }

    .mobile-contact-view {
        font-size: 13px !important;
        line-height: 14px !important;
        padding: 14px !important;
        width: 75% !important;
        margin-right: 38px !important;
    }

    .contact-bottom-cloud {
        margin-bottom: -23px !important;
    }

    .feature-cloud-mobile {
        display: block !important;
        width: 177px;
    }

    .responsive-cloud {
        display: none !important;
    }

    .left-feature {
        margin-top: 50px !important;
    }

    .right-feature {
        margin-top: 50px !important;
    }

    .streamline-container {
        display: none !important;
    }

    .menu-item {
        font-size: 13px !important;
        line-height: 18px !important;
        padding-top: 2px !important;
    }

    .menu-heading {
        font-size: 14px !important;
    }

    .vertical-line {
        height: 190px !important;
        top: 25px !important;
    }

    .social-handle-section {
        margin-left: -27px !important;
    }

    .powered-by-text {
        font-size: 12px !important;
    }

    .follow-us-text {
        margin-left: -10px !important;
    }

    .streamline-inner-box {
        margin-top: 53px !important;
    }

    .streamline-text-mobile {
        font-size: 22px !important;
        padding-right: 33px !important;
        padding-left: 4px !important;
        line-height: 27px !important;
        padding-top: 10px !important;
    }

    .feature-subheading {
        font-size: 19px !important;
        line-height: 27.15px !important;
    }

    .feature-description {
        font-size: 16px !important;
        line-height: 22.68px !important;
    }

    .feature-content-section {
        padding: 13px !important;
    }

    .schedule-demo-mobile-view {
        margin-left: -2px !important;
        text-align: left !important;
    }

    .social-handle-section {
        margin-top: 50px !important;
    }

    .linked-logo {
        margin-right: 5px !important;
        width: 23px;
    }

    .facebook-logo {
        width: 23px !important;
        margin-right: 4px !important;
        margin-left: 13px !important;
    }

    .streamline-inner-box {
        .cxotulz-logo {
            max-width: none !important;
            width: 130px !important;
            padding: 0 5px;
            margin-left: 3vw !important;
        }

        .wmp-logo {
            max-width: none !important;
            width: 90px !important;
        }
    }

    .our-role-width {
        width: 160px !important;
    }

    .hamburger-menu {
        display: block !important;
        grid-column: 1/2 !important;
        margin-top: -53px !important;
        margin-left: 31px !important;
    }

    .mobile-menu-link ul {
        margin-top: -543px !important;
        margin-left: -20px !important;
    }

    .email-error-message {
        padding-left: 10% !important;
        margin-right: 37px !important;
    }
}

@media screen and (min-width:360px) and (max-width:419px) {
    .cost-analysis {
        top: 43vw !important;
        left: 55vw !important;
        width: 155px !important;
    }

    .statistics {
        top: -5vw !important;
        right: 42vw !important;
        width: 200px !important;
    }

    .actions {
        top: 25vw !important;
        left: 6vw !important;
        width: 189px !important;
    }

}

@media only screen and (min-width: 332px) and (max-width: 419px) {
    .streamline-box {
        padding-top: 27px !important;
    }
}

@media screen and (min-width:420px) and (max-width:427px) {
    .hero-container {
        height: 1540px !important;
    }
}

@media screen and (min-width:428px) and (max-width:429px) {
    .hero-container {
        height: 1510px !important;
    }
}

@media screen and (min-width:430px) and (max-width:439px) {
    .hero-container {
        height: 1540px !important;
    }

    .graph-charts {
        top: -181px !important;
        right: 22px !important;
    }
}

@media screen and (min-width:440px) and (max-width:449px) {
    .hero-container {
        height: 1550px !important;
    }

    .graph-charts {
        top: -181px !important;
        right: 24px !important;
    }
}

@media screen and (min-width:451px) and (max-width:452px) {
    .hero-container {
        height: 1565px !important;
    }

    .graph-charts {
        top: -190px !important;
        right: 24px !important;
    }
}

@media screen and (min-width:450px) and (max-width:455px) {
    .graph-charts {
        top: -187px !important;
        right: 23px !important;
    }
}

@media screen and (min-width:453px) and (max-width:455px) {
    .hero-container {
        height: 1560px !important;
    }

    .graph-charts {
        top: -190px !important;
        right: 24px !important;
    }
}

@media only screen and (min-width: 451px) and (max-width: 455px) {
    .hero-container {
        height: 1655px !important;
    }
}

@media screen and (min-width:472px) and (max-width:482px) {
    .hero-container {
        height: 1680px !important;
    }

    .graph-charts {
        top: -201px !important;
        right: 24px !important;
    }
}

@media screen and (min-width:483px) and (max-width:499px) {
    .hero-container {
        height: 1628px !important;
    }

    .graph-charts {
        top: -218px !important;
        right: 24px !important;
    }
}

@media screen and (min-width:492px) and (max-width:499px) {
    .hero-container {
        height: 1625px !important;
    }

    .graph-charts {
        top: -219px !important;
        right: 24px !important;
    }
}

@media screen and (min-width:406px) and (max-width:409px) {
    .hero-container {
        height: 1500px !important;
    }
}

@media screen and (min-width:410px) and (max-width:419px) {
    .banner-clouds {
        top: -707px !important;
    }

    .graph-charts {
        top: -166px !important;
    }

    .hero-container {
        height: 1480px !important;
    }
}

@media screen and (min-width:417px) and (max-width:419px) {
    .hero-container {
        height: 1480px !important;
    }
}

@media screen and (min-width:400px) and (max-width:409px) {
    .banner-clouds {
        top: -677px !important;
    }

    .graph-charts {
        top: -161px !important;
    }
}

@media screen and (min-width:380px) and (max-width:399px) {
    .banner-clouds {
        top: -641px !important;
    }

    .graph-charts {
        top: -156px !important;
    }
}

@media screen and (min-width:360px) and (max-width:379px) {
    .banner-clouds {
        top: -623px !important;
    }

    .graph-charts {
        top: -145px !important;
    }
}

@media screen and (min-width:340px) and (max-width:359px) {
    .banner-clouds {
        top: -570px !important;
    }

    .graph-charts {
        top: -127px !important;
    }
}

/* media query for schedule demo section start */
@media (max-width: 1800px) {
    .cxo-charts {
        top: -10vw !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -20vw !important;
    }

    .cxo {
        top: -106px !important;
        right: -305px !important;
    }

    .schedule-demo {
        font-size: 52px !important;
        top: 57px !important;
    }

    .form-box {
        top: 103px !important;
    }

    .one-linear-input-box {
        width: 30vw !important;
    }

    .name-box {
        width: 15vw !important;
    }

    .form-container {
        height: 728px !important;
    }
}

@media screen and (min-width:1700px) and (max-width:1742px) {
    .dashboard {
        top: -36vw !important;
    }
}

@media only screen and (min-width: 1742px) and (max-width: 1800px) {
    .demo-container {
        height: 920px !important;
    }

    .cxo-charts {
        top: -10vw !important;
        right: -20vw !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -17vw !important;
    }

    .cxo {
        top: -106px !important;
        right: -305px !important;
    }

    .schedule-demo {
        font-size: 58px !important;
        top: 43px !important;
    }

    .form-box {
        top: 61px !important;
    }

    .unlock-text {
        top: 33vw !important;
        right: -1vw !important;
        line-height: 41px !important;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .form-container {
        height: 685px !important;
        margin-top: 5% !important;
        width: 38vw !important;
        margin-left: 11vw !important;
    }

    .statistics {
        top: 4vw !important;
        left: 2vw !important;
        width: 412px !important;
    }

    .comments {
        left: 26vw !important;
        top: 4vw;
        width: 287px !important;
    }

    .cost-analysis {
        top: 20vw !important;
        left: 27vw !important;
        width: 466px !important;
    }

    .actions {
        top: 14vw !important;
        left: 5vw !important;
        width: 374px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 333px !important;
    }
}

@media only screen and (min-width: 1800px) and (max-width: 1800px) {
    .about-right-section {
        height: 670px !important;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
    .demo-container {
        height: 895px !important;
        margin-top: 136px !important;
    }

    .cxo-charts {
        right: 0vw !important;
        top: -191px !important;
        left: 15% !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -40vw !important;
    }

    .cxo {
        top: -106px !important;
        right: -305px !important;
    }

    .schedule-demo {
        font-size: 56px !important;
        top: 42px !important;
    }

    .form-box {
        top: 61px !important;
    }

    .unlock-text {
        top: 35vw !important;
        right: -1vw !important;
        line-height: 40px !important;
        font-size: 35px !important;
        width: 40% !important;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .cloud-platform {
        width: 280px;
    }

    .form-container {
        height: 675px !important;
        margin-top: 5% !important;
        margin-left: 10vw !important;
        width: 37vw !important;
    }

    .cost-analysis {
        top: 19vw !important;
        left: 26vw !important;
        width: 426px !important;
    }

    .comments {
        left: 26vw !important;
        top: 3vw;
        width: 239px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 299px !important;
    }

    .statistics {
        top: 3vw !important;
        left: 3vw !important;
        width: 357px !important;
    }

    .actions {
        top: 14vw !important;
        left: 5vw !important;
        width: 311px !important;
    }
}


@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .cxo-charts {
        right: 0vw !important;
        top: -214px !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -37vw !important;
    }

    .cxo {
        top: -90px !important;
        right: -218px !important;
    }

    .schedule-demo {
        font-size: 46px !important;
        top: 31px !important;
    }

    .form-box {
        top: 36px !important;
    }

    .unlock-text {
        top: 39vw !important;
        right: 3vw !important;
        line-height: 37px !important;
        font-size: 28px !important;
        width: 47% !important;
    }

    .cloud-platform {
        width: 280px;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .form-container {
        height: 655px !important;
        margin-top: 70px;
        width: 37vw !important;
    }

    .statistics {
        top: 3vw !important;
        left: 2vw !important;
        width: 306px !important;
    }

    .comments {
        left: 28vw !important;
        top: 5vw;
        width: 215px !important;
    }

    .cost-analysis {
        top: 19vw !important;
        left: 25vw !important;
        width: 395px !important;
    }

    .actions {
        top: 15vw !important;
        left: 5vw !important;
        width: 270px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 259px !important;
    }

}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
    .cxo-charts {
        right: -6vw !important;
        top: -185px !important;
    }

    .dashboard {
        top: -42vw !important;
    }

    .unlock-text {
        top: 36vw !important;
        font-size: 32px !important;
    }

    .cost-analysis {
        top: 19vw !important;
        left: 26vw !important;
        width: 402px !important;
    }

    .comments {
        left: 26vw !important;
        top: 3vw;
        width: 239px !important;
    }

    .monthly-reports {
        top: 11vw !important;
        left: 14vw !important;
        width: 277px !important;
    }

    .statistics {
        top: 3vw !important;
        left: 3vw !important;
        width: 357px !important;
    }

    .actions {
        top: 14vw !important;
        left: 5vw !important;
        width: 311px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .cxo-charts {
        right: 0vw !important;
        top: -199px !important;
        left: 2px !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -42vw !important;
    }

    .cxo {
        top: -106px !important;
        right: -218px !important;
    }

    .schedule-demo {
        font-size: 42px !important;
        top: 33px !important;
    }

    .form-box {
        top: 41px !important;
    }

    .hero-container {
        height: 1025px !important;
    }

    .unlock-text {
        top: 45vw !important;
        right: 9vw !important;
        line-height: 35px !important;
        font-size: 28px !important;
        width: 60% !important;
    }

    .cloud-platform {
        width: 200px;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .form-container {
        height: 715px !important;
        margin-left: 5vw !important;
        margin-top: 6%;
    }

    .cxoTulz-content {
        align-items: start;
    }

    .effortlessly-content {
        font-size: 28px !important;
        line-height: 34px !important;
    }

    .effortlessly-content-1 {
        font-size: 28px !important;
        line-height: 34px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1300px) {
    .hero-container {
        height: 955px !important;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
    .dashboard {
        top: -40vw !important;
    }

    .cxo-charts {
        left: -11px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
    .cxo-charts {
        right: -8vw !important;
        top: -145px !important;
    }

    .cxo-dashboard {
        top: 0vw !important;
    }

    .dashboard {
        top: -55vw !important;
        right: -9vw !important;
    }

    .cxo {
        top: -59px !important;
        right: -167px !important;
    }

    .schedule-demo {
        font-size: 42px !important;
        top: 35px !important;
        line-height: 47px !important;
    }

    .form-box {
        top: 50px !important;
    }

    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 54px;
        text-align: center;
        color: #000000;
        margin-bottom: 69px;
        padding: 0 6px;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 29vw !important;
    }

    .name-box {
        width: 14vw !important;
    }

    .get-start-btn {
        width: 29vw !important;
    }

    .form-container {
        height: 660px !important;
        width: 36vw !important;
        margin-left: 6vw !important;
        margin-top: 4%;
    }

    .cxoTulz-content {
        align-items: center !important;
    }
}

@media only screen and (min-width: 1120px) and (max-width: 1199px) {
    .dashboard {
        top: -52vw !important;
        right: -6vw !important;
    }

    .cxo-charts {
        right: -6vw !important;
        top: -158px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 999px) {
    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        color: #000000;
    }

    .cxo {
        top: -83px !important;
        right: -265px !important;
    }

    .cloud-platform {
        width: 43%;
    }

    .schedule-demo {
        font-size: 40px !important;
        top: 50px !important;
        line-height: 47px !important;
    }

    .form-box {
        top: 69px !important;
    }

    .form-container {
        width: 66vw !important;
        margin: 114px !important;
        margin-top: 35px !important;
        right: 55px;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 50vw !important;
    }

    .name-box {
        width: 24vw !important;
    }

    .name-input-box {
        width: 53vw !important;
    }

    .get-start-btn {
        width: 50vw !important;
    }

    .form-container {
        height: 650px !important;
    }
}

@media only screen and (min-width: 625px) and (max-width: 766px) {
    .form-box {
        top: -95px !important;
    }

    .schedule-demo {
        font-size: 42px !important;
        top: -120px !important;
        line-height: 47px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 766px) {
    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #000000;
    }

    .cloud-platform {
        width: 40%;
    }

    .cxo {
        top: -90px !important;
        right: -198px !important;
    }

    .schedule-demo {
        font-size: 36px !important;
        top: 49px !important;
        line-height: 42px !important;
    }

    .form-box {
        top: 70px !important;
    }

    .form-container {
        height: 675px !important;
        right: 25px;
        width: 68vw !important;
        margin: 74px !important;
        margin-top: 56px !important;
    }

    .name-input-box {
        width: 78vw !important;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 50vw !important;
    }

    .name-box {
        width: 24vw !important;
    }

    .name-input-box {
        width: 53vw !important;
    }

    .get-start-btn {
        width: 50vw !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .form-container {
        right: 35px !important;
    }

    .our-feature-role {
        width: 170px !important;
    }
}

@media only screen and (min-width: 606px) and (max-width: 767px) {
    .form-box {
        top: 66px !important;
    }

    .schedule-demo {
        top: 51px !important;
    }

    .form-container {
        height: 660px !important;
    }

    .vertical-line {
        height: 205px !important;
    }

    .social-handle-section {
        margin-left: 0px !important;
    }

    .streamline-inner-box {
        margin-top: 21px !important;
    }
}

@media only screen and (min-width: 420px) and (max-width: 575px) {
    .hamburger-menu {
        display: block !important;
        grid-column: 1/2 !important;
        margin-top: -57px !important;
        margin-left: 71px !important;
    }

    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 41px;
        text-align: center;
        color: #000000;
    }

    .cxo {
        top: -53px !important;
        right: -145px !important;
        height: 148px !important;

    }

    .cloud-platform {
        width: 55%;
    }

    .schedule-demo {
        font-size: 34px !important;
        top: 33px !important;
        line-height: 40px !important;
    }

    .form-box {
        top: 40px !important;
    }

    .form-container {
        width: 78vw !important;
        margin: 40px !important;
    }

    .name-input-box {
        width: 78vw !important;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 70vw !important;
    }

    .name-box {
        width: 32vw !important;
    }

    .get-start-btn {
        width: 70vw !important;
    }

    .form-container {
        height: 650px !important;
        right: 12px;
    }

    .our-feature-role {
        width: 170px !important;
    }

    .cxo-logo {
        width: 162px !important;
    }

    .streamline-inner-box {
        .cxotulz-logo {
            max-width: none !important;
            width: 180px !important;
            padding: 0 5px;
            padding-left: 18px;
        }
    }

    .streamline-box {
        padding-top: 24px !important;
    }
}

@media only screen and (min-width: 472px) and (max-width: 499px) {
    .streamline-box {
        padding-top: 15px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 575px) {
    .form-container {
        right: 20px !important;
    }

    .form-box {
        top: 67px !important;
    }

    .schedule-demo {
        top: 45px !important;
        font-size: 36px !important;
    }

    .cxo {
        top: -75px !important;
        right: -180px !important;
        height: 155px !important;
    }

    .feature-cloud-mobile {
        display: block !important;
        width: 216px;
    }

    .responsive-cloud {
        display: none !important;
    }

    .feature-cloud-mobile {
        display: block !important;
    }

    .powered-by-text {
        font-size: 16px !important;
    }

    .menu-heading {
        font-size: 18px !important;
    }

    .follow-us-text {
        margin-left: -29px !important;
        padding-top: 5px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 535px) {
    .graph-charts {
        right: 26px !important;
    }

    .streamline-box {
        padding-top: 10px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #000000;
    }

    .cxo {
        top: -40px;
        right: -119px;
        height: 110px;
    }

    .schedule-demo {
        font-size: 30px !important;
        top: 44px !important;
        line-height: 35px !important;
        padding-left: 10% !important;
        padding-right: 10% !important;
    }

    .form-box {
        top: -44px !important;
    }

    .form-container {
        height: 630px !important;
        width: 78vw !important;
        margin: 21px !important;
        right: 25px;
    }

    .name-input-box {
        width: 78vw !important;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 72vw !important;
    }

    .name-box {
        width: 34vw !important;
    }

    .get-start-btn {
        width: 72vw !important;
    }

    .cloud-platform {
        width: 200px;
    }

    .our-feature-role {
        width: 170px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 394px) {
    .unlock-responsive-content {
        display: block !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        color: #000000;
    }

    .cxo {
        top: -40px !important;
        right: -113px !important;
        height: 110px !important;
    }

    .schedule-demo {
        font-size: 28px !important;
        top: 47px !important;
        line-height: 33px !important;
    }

    .form-box {
        top: 48px !important;
    }

    .form-container {
        height: 636px !important;
        width: 81vw !important;
        margin: 21px !important;
        right: 6px;
        margin-top: 25px !important;
    }

    .name-input-box {
        width: 78vw !important;
    }

    .unlock-text {
        display: none;
    }

    .one-linear-input-box {
        width: 72vw !important;
    }

    .name-box {
        width: 36vw !important;
    }

    .get-start-btn {
        width: 69vw !important;
    }

    .cloud-platform {
        width: 200px;
    }
}

@media only screen and (min-width: 334px) and (max-width: 394px) {
    .form-container {
        height: 610px !important;
    }

    .demo-container {
        height: 845px !important;
    }
}

@media only screen and (min-width: 395px) and (max-width: 419px) {
    .cxo {
        top: -45px !important;
        right: -128px !important;
        height: 120px !important;
    }

    .form-box {
        top: 74px !important
    }

    .name-box {
        width: 33vw !important;
    }
}

@media screen and (min-width:330px) and (max-width:349px) {
    .hero-container {
        height: 1505px !important;
    }
}

@media screen and (min-width:341px) and (max-width:349px) {
    .hero-container {
        height: 1490px !important;
    }
}

@media screen and (min-width:350px) and (max-width:365px) {
    .hero-container {
        height: 1500px !important;
    }
}

@media screen and (min-width:366px) and (max-width:373px) {
    .hero-container {
        height: 1515px !important;
    }
}

@media screen and (min-width:374px) and (max-width:376px) {
    .hero-container {
        height: 1525px !important;
    }
}

@media screen and (min-width:377px) and (max-width:382px) {
    .hero-container {
        height: 1525px !important;
    }
}

@media screen and (min-width:383px) and (max-width:388px) {
    .hero-container {
        height: 1535px !important;
    }
}

@media screen and (min-width:390px) and (max-width:400px) {
    .hero-container {
        height: 1550px !important;
    }
}

@media screen and (min-width:402px) and (max-width:405px) {
    .hero-container {
        height: 1530px !important;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) {
    .effortlessly-content-1 {
        text-align: center !important;
        font-size: 28px !important;
    }

    .effortlessly-content {
        text-align: center !important;
        font-size: 28px !important;
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    .about-left-section {
        padding-top: 1%;
    }
}

@media screen and (min-width: 3300px) and (max-width: 3500px) {
    .hero-container {
        height: 940px !important;
    }
}

@media screen and (min-width:2000px) and (max-width:2200px) {
    .nav-text {
        font-size: 20px !important;
    }

    .schedule-btn {
        font-size: 19px !important;
    }

    .menu-heading {
        font-size: 24px !important;
    }

    .menu-item {
        font-size: 19px !important;
    }
}

@media screen and (min-width:2201px) and (max-width:2400px) {
    .nav-text {
        font-size: 22px !important;
    }

    .schedule-btn {
        font-size: 21px !important;
    }

    .logoSection {
        & img {
            width: 240px !important;
        }
    }

    .menu-heading {
        font-size: 24px !important;
    }

    .menu-item {
        font-size: 19px !important;
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .nav-text {
        font-size: 24px !important;
    }

    .schedule-btn {
        font-size: 23px !important;
    }

    .logoSection {
        & img {
            width: 250px !important;
        }
    }

    .menu-heading {
        font-size: 30px !important;
    }

    .menu-item {
        font-size: 24px !important;
    }

}

@media screen and (min-width:2801px) and (max-width:3200px) {
    .nav-text {
        font-size: 26px !important;
    }

    .schedule-btn {
        font-size: 25px !important;
    }

    .logoSection {
        & img {
            width: 280px !important;
        }
    }

    .menu-heading {
        font-size: 32px !important;
        margin-bottom: 1.4rem !important;
    }

    .menu-item {
        font-size: 26px !important;
    }
}

@media screen and (min-width:3201px) and (max-width:3500px) {
    .nav-text {
        font-size: 29px !important;
    }

    .email-error-message {
        padding-left: 10% !important;
        font-size: 27px !important;
    }

    .schedule-btn {
        font-size: 27px !important;
    }

    .logoSection {
        & img {
            width: 300px !important;
        }
    }

    .effortlessly-content {
        font-size: 35px !important;
        line-height: 41px !important;
    }

    .effortlessly-content-1 {
        font-size: 35px !important;
        line-height: 41px !important;
    }

    .menu-heading {
        font-size: 32px !important;
        margin-bottom: 1.4rem !important;

    }

    .menu-item {
        font-size: 26px !important;
    }
}

@media screen and (min-width:2801px) and (max-width:3500px) {
    .contact-input {
        font-size: 20px !important;
        padding: 16px !important;
        width: 80% !important;
        margin-right: 20px !important;
    }

    .contact-btn {
        font-size: 21px !important;
        line-height: 20px !important;
        padding: 17px !important;
        width: 100% !important;
    }
}

@media screen and (min-width:2000px) and (max-width:2200px) {
    .feature {
        max-width: none !important;
        width: 240px !important;
        margin-top: 39px !important
    }
}

@media screen and (min-width:2201px) and (max-width:2400px) {
    .feature {
        max-width: none !important;
        width: 270px !important;
        margin-top: 23px !important
    }
}

@media screen and (min-width:2401px) and (max-width:2600px) {
    .feature {
        max-width: none !important;
        width: 300px !important;
        margin-top: 5px !important
    }
}

@media screen and (min-width:2601px) and (max-width:2999px) {
    .feature {
        max-width: none !important;
        width: 320px !important;
        margin-top: 5px !important
    }
}

@media screen and (min-width:3000px) {
    .feature {
        max-width: none !important;
        width: 330px !important;
        margin-top: 5px !important
    }

    .feature-lines {
        top: -67px !important;
    }

    .about-left-section {
        height: 50vh !important;
    }

    .email-error-message {
        padding-left: 11% !important;
        font-size: 25px !important;
    }
}

@media screen and (min-width:3199px) and (max-width:3500px) {
    .vertical-line {
        height: 275px !important;
    }
}

@media screen and (min-width:3201px) and (max-width:3500px) {
    .email-error-message {
        padding-left: 10% !important;
        font-size: 27px !important;
    }
}

@media screen and (min-width:768px) and (max-width:1199px) {
    .schedule-btn {
        margin-top: 16% !important;
    }

    .facebook-logo {
        width: 30px !important;
        margin-left: 13px !important;
        margin-right: 8px !important;
        margin-top: -2px !important;
    }

    .facebook-section {
        margin-top: 4px !important;
    }

    .menu-item {
        padding-top: 4px !important;
    }

    .follow-us-text {
        padding-top: 3px !important;
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .schedule-btn {
        margin-top: 10% !important;
    }

    .menu-item {
        padding-top: 2px !important;
    }
}

@media screen and (min-width:892px) and (max-width:991px) {
    .cloud-mgt-circle {
        width: 63% !important;
        left: -10px !important;
        top: -111px !important;
    }
}

@media screen and (min-width:420px) and (max-width:767px) {
    .facebook-logo {
        width: 30px !important;
        margin-left: 13px !important;
        margin-right: 8px !important;
        margin-top: -2px !important;
    }

    .menu-item {
        padding-top: 2px !important;
    }
}

@media screen and (min-width:2000px) {
    .facebook-logo {
        margin-right: 9px !important;
        width: 30px !important;
        margin-left: 21px !important;
    }
}

@media screen and (min-width:1200px) {
    .facebook-logo {
        margin-right: 9px !important;
        width: 30px !important;
        margin-left: 17px !important;
    }
}

@media screen and (min-width: 2801px) {
    .facebook-logo {
        margin-right: 9px !important;
        width: 29px !important;
        margin-left: 22px !important;
        padding-top: 4px !important;
    }
}

@media screen and (min-width:1800px) and (max-width:1800px) {
    .contact-input {
        width: 80% !important;
    }
}

@media screen and (min-width:478px) and (max-width:510px) {
    .feature-overall-container {
        height: 740px !important;
    }
}

@media screen and (min-width:402px) and (max-width:419px) {
    .feature-overall-container {
        height: 860px !important;
    }
}

@media screen and (min-width:361px) and (max-width:401px) {
    .feature-overall-container {
        height: 860px !important;
    }
}

@media screen and (min-width:335px) and (max-width:360px) {
    .feature-overall-container {
        height: 830px !important;
    }
}