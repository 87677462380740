@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.main-nav {
	display: grid;
	width: 100%;
	height: 7rem;
	grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
	animation-delay: 1000s;
	position: fixed;
	top: 0;
	z-index: 9999999;
}

a {
	text-decoration: none !important;
}

a:hover {
	color: #007EA7 !important;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.04em;
	text-decoration: none !important;
}

li {
	list-style: none;
}

.logoSection {
	display: grid;
	grid-column: 2/3;
	justify-content: start;
	align-items: center;
	position: sticky;
}

.menu-link {
	grid-column: 3/4;
}

.menu-link ul {
	height: 6rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.nav-text {
	letter-spacing: 0.04em;
	color: #000000;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
}

.schedule-btn {
	color: #FFFDF7;
	background-color: #007EA7;
	border-radius: 50px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	border-style: none;
	padding: 17px 30px;
	box-shadow: 1px 1px 5px #888888;
	margin-top: 8%;
}

.schedule-btn:hover {
	background-color: #006C8F;
	cursor: pointer;
}

.schedule-demo-btn {
	grid-column: 4/5;
	justify-content: end;
	align-items: center;
	display: grid;
	height: 6rem;
}

.hamburger-menu {
	display: none;
	grid-column: 1/2;
	margin-top: 30px;
}

.schedule-button {
	display: none;
}

.hamburger-menu {
	display: none;
	grid-column: 1/2;
	margin-top: -60px;
	margin-left: 100px;
}
.mobile-menu-link {
    height: 15rem !important;
    grid-column: 1/5 !important;
    top: 77% !important;
    padding-left: 2rem !important;
    height: 100vh !important;
}

.mobile-menu-link ul {
	height: 15rem;
	margin-top: -543px !important;
}

.highlighted-header {
     background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 21.15%, rgba(255, 255, 255, 0.681818) 41.98%, rgba(255, 255, 255, 0.378788) 62.81%, rgba(255, 255, 255, 0) 88.85%);
  }

  .transparent-navbar {
	background-color: transparent !important;
	transition: background-color 0.3s ease-in-out;
  }
  
  .solid-navbar {
	background-color: #ffffff !important; /* Change this color to the solid color you want */
	transition: background-color 0.3s ease-in-out;
  }

.horizontal-lines {
	width: 120px;
}

.nav-horizontal-line {
	text-align: left;
}
  
.nav-horizontal {
	width: 100%;
}

.nav-text {
	cursor: pointer;
}