.chart-section-inner {
    position: absolute;
    overflow-x: hidden !important;
}

.form-container {
    background-color: #2E353D;
    height: 75vh;
    border-radius: 20px;
    position: absolute;
    width: 33vw;
    margin-left: 8vw;
}

.cxo-charts {
    position: relative;
    top: -12vw;
    right: -15vw;
    z-index: 9;
}

.cxo-dashboard {
    position: relative;
    float: right;
}

.dashboard {
    position: relative;
    top: -20vw;
    float: right;
}

.demo-container {
    margin-top: 15vh;
    height: 100vh;
    background-image: url('../images/schedule-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    visibility: hidden;
}

.schedule-visible {
    visibility: visible;
}

.schedule-demo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 60px;
    color: #FFFFFF;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    top: -121px;
}

.input-box {
    padding: 14px;
    margin-bottom: 20px;
    background: #EBEBEB;
    border-radius: 38px;
    border-style: none;
    display: flex;
    justify-content: center;
}

.one-linear-input-box {
    width: 28vw;
}

.get-start-btn {
    width: 28vw;
    background: #007EA7;
    border-radius: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFDF7;
    border-style: none;
    padding: 18px;
}

.unlock-text {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 45px;
    color: #000000;
    width: 45%;
    top: -13vw;
}

.cxo {
    position: relative;
    top: -106px;
    right: -305px;
}

.name-input-box {
    display: flex;
    flex-direction: row;
    width: 31vw;
    position: relative;
    justify-content: space-evenly;
}

.form-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -40px;
}

.unlock-responsive-content {
    display: none;
}

.country-select>.css-13cymwt-control,
.country-select>.css-t3ipsp-control {
    padding: 10px;
    margin-bottom: 20px;
    background: #EBEBEB;
    border-radius: 38px !important;
    border-style: none !important;
    align-items: start !important;
    text-align: left !important;
}

.error-message {
    color: red;
    margin-top: -20px !important;
    text-align: left;
    margin-left: 5%;
}

.name-box {
    width: 13vw;
}

.get-start-btn:hover {
    background-color: #006C8F;
    cursor: pointer;
}