.feature-overall-container {
    background-image: url('../images/feature-background.svg');
    background-repeat: no-repeat;
    height: 100vh;
    /* top: 28vh; */
    position: relative;
    background-size: 100% 100%;
}

.effortless-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 77px;
    text-align: center;
    color: #000000;
    padding-top: 55px;
}

.feature-container {
    position: absolute;
    overflow: hidden;
}

.feature-cloud {
    position: relative;
    top: 260px;
    z-index: 9;
}

.feature-lines {
    position: relative;
    top: -86px;
    right: 102px;
    z-index: -1;
}

.feature {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 9 !important;
}

.feature:hover {
    transform: scale(1.2);
}

.feature-box {
    visibility: hidden;
}

.feature-box-animations {
    visibility: visible !important;
}

.our-feature {
    background-image: url("../images/cloud-mgt.svg");
    background-size: cover;
    animation: fade-in 2s ease-out forwards;
    height: 110px;
    width: 55%;
    top: -6vw;
    position: relative;
    left: 31vw;
    transform: scale(0.6);
}

.responsiveness-cloud {
	display: flex;
	justify-content: center;
	align-items: center;
}

.our-feature-width {
	width: 400px;
}

.responsive-cloud {
	display: none;
}

.responsive-feature-lines {
	display: none;
}

.feature-cloud-mobile {
    display: none;
}

.close-feature-lines {
    display: none;
}