.feature-subheading {
    font-size: 28px;
    font-weight: 500;
    line-height: 38.15px;
}

.feature-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 32.68px;
}

.feature-content-section {
    text-align: left;
    padding: 20px;
}