.about-left-section {
    background-image: url('../images/about-section-left-bg.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 75vh;
}

.about-section-bg {
    background-color: #FFFFFF;
}

.about-right-section {
    background-image: url('../images/about-section-right-bg.svg');
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.monthly-reports {
    position: absolute;
    top: 9vw;
    left: -23vw;
    z-index: 99;
}

.actions {
    position: absolute;
    top: 16vw;
    left: -53vw;
    z-index: 9;
}

.comments {
    position: absolute;
    left: 8vw;
    z-index: 999;
}

.cost-analysis {
    position: absolute;
    top: 19vw;
    left: -48vw;
    z-index: 99;
}

.statistics {
    position:absolute;
    top: 2vw;
    right: -3vw;
}

.charts-section {
    position: relative;
    visibility: hidden;
}

.actionable-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 77px;
    color: #FFFFFF;
    text-align: start;
}

.cxoTulz-content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: start;
}

.about-right-content {
    width: 85%;
    visibility: hidden;
}

.animate-right {
    overflow-x: hidden !important;
}

.about-container {
    overflow-x: hidden !important;
    position: relative;
}

.chart-section-animation-effect {
    visibility: visible;
}

.right-container-animation-effect {
    visibility: visible;
}