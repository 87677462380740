.contact-us-box {
    background-color: #007EA7;
    overflow: hidden !important;
    visibility: hidden;
}

.left-cloud {
    float: left !important;
}

.contact-details {
    margin-bottom: 40px;
}

.right-cloud {
    float: right !important;
}

.we-love-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 77px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 70px;
}

.contact-input {
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF !important;
    padding: 17px;
    width: 100%;
    margin-right: 20px;
    background-color: #007EA7;
}

.contact-btn {
    background: #FFFFFF;
    border-radius: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #007EA7;
    border-style: none;
    padding: 17px;
    width: 100%;
}

.contact-input::placeholder {
    color: #FFFFFF;
}

.left-cloud-container {
    padding-left: 0px !important;
}

.right-cloud-container {
    padding-right: 0px !important;
}

.animate-left {
    overflow-x: hidden !important;
}

.animate-right {
    overflow-x: hidden !important;
}

.contact-section-visible {
    visibility: visible;
}

.contact-box {
    display: flex;
    justify-content: center;
}

.contact-div {
    width: 50%;
}

.contact-div-btn {
    width: 20%;
}

.email-error-message {
    top: 10px;
    color: red;
    text-align: left;
    padding-left: 8%;
}

.contact-btn:hover {
    background-color: #fbf6f6;
    cursor: pointer;
}

.footer-email {
    display: flex;
    justify-content: start;
}